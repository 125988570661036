import { useState } from "react";
import { Container, TextField } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Button } from "@mui/material";
import CustomCheckbox from "../../components/checkbox";
import { IKiosk } from "../../globalprops";
import DynamicFields from "../../components/dynamicFields";
import { JsonParser } from "../../globalhelper";
import BannerConfig from "./bannerconfig.component";
import HeaderConfig from "./headerconfig.component";

type TData = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
  submitForm: any;
  setModalOpen: React.Dispatch<React.SetStateAction<any>>;
};

const GenericUiconfig = ({
  kioskState,
  setKioskState,
  submitForm,
  setModalOpen,
}: TData) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  let hrk = 900;
  let brk = 300;
  if (kioskState.templatemeta.templatedata.header?.sliderData) {
    hrk =
      kioskState.templatemeta.templatedata.header.sliderData.length +
      3 +
      Date.now();
  }
  if (kioskState.templatemeta.templatedata.buttons) {
    brk = kioskState.templatemeta.templatedata.buttons.length + 5 + Date.now();
  }
  const [headerRandomKey, setHeaderRandomKey] = useState(hrk);
  const [buttonRandomKey, setButtonRandomKey] = useState(brk);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const HeaderTypeHandler = (e: any) => {
    if (e?.target?.defaultValue === "isvideo") {
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState?.templatemeta?.templatedata,
            header: {
              ...kioskState.templatemeta.templatedata.header,
              isSlider: false,
              isvideo: true,
            },
          },
        },
      });
    } else {
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState?.templatemeta?.templatedata,
            header: {
              ...kioskState.templatemeta.templatedata.header,
              isSlider: true,
              isvideo: false,
            },
          },
        },
      });
    }
  };
  const AddNewHeaderField = () => {
    setHeaderRandomKey((prev) => prev + 1);
    if (
      kioskState?.templatemeta?.templatedata?.header?.sliderData &&
      kioskState?.templatemeta?.templatedata?.header?.videoData
    ) {
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState.templatemeta.templatedata,
            header: {
              ...kioskState.templatemeta.templatedata.header,
              sliderData: [
                ...kioskState.templatemeta.templatedata.header.sliderData,
                {
                  source: "",
                  linkto: "",
                  isActive: true,
                  order: 1,
                  caption: "",
                  key: headerRandomKey,
                },
              ],
              videoData: [
                ...kioskState.templatemeta.templatedata.header.videoData,
              ],
            },
          },
        },
      });
    }
  };
  const removeHeaderField = (index: any) => {
    kioskState?.templatemeta?.templatedata?.header?.sliderData?.splice(
      index,
      1,
    );
    setKioskState({ ...kioskState });
  };
  const removeButtonField = (index: any) => {
    kioskState?.templatemeta?.templatedata?.buttons?.splice(index, 1);
    setKioskState({ ...kioskState });
  };

  const AddNewButtonField = () => {
    setButtonRandomKey((prev) => prev + 1);
    if (kioskState.templatemeta.templatedata.buttons) {
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState.templatemeta.templatedata,
            buttons: [
              ...kioskState.templatemeta.templatedata.buttons,
              {
                key: buttonRandomKey,
                name: "",
                imagesrc: "",
                linkto: "",
                order: 1,
                css: {},
              },
            ],
          },
        },
      });
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container style={{ paddingBottom: "2em" }}>
        <Grid item xs={2}>
          <TextField
            label="Kiosk Name"
            color="primary"
            disabled
            value={kioskState?.kioskname}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Height"
            color="primary"
            disabled
            // onChange={(e) =>
            //   setKioskState({
            //     ...kioskState,
            //     kioskheight:e?.target?.value
            //   })
            // }
            value={kioskState?.kioskheight}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Width"
            color="primary"
            disabled
            // onChange={(e) =>
            //   setKioskState({
            //     ...kioskState,
            //     kioskwidth:e?.target?.value
            //   })
            // }
            value={kioskState?.kioskwidth}
          />
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={() => setModalOpen((prev: boolean) => !prev)}
          >
            Preview
            <RemoveRedEyeIcon style={{ paddingLeft: "15px" }} />
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <TextField
            label="Template Name"
            color="primary"
            disabled
            value={kioskState?.templatemeta?.templatedata?.templatename}
          />
        </Grid>
      </Grid>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Banner</Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Banner related properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <BannerConfig
              kioskState={kioskState}
              setKioskState={setKioskState}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Header</Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Header related properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <HeaderConfig
              kioskState={kioskState}
              setKioskState={setKioskState}
              HeaderTypeHandler={HeaderTypeHandler}
              removeHeaderField={removeHeaderField}
              AddNewHeaderField={AddNewHeaderField}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Divider</Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Divider (that seperates the header from buttons) related properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Source"
                    multiline={false}
                    size="medium"
                    maxRows={50000}
                    value={
                      kioskState?.templatemeta?.templatedata?.slicer?.source
                    }
                    onChange={(e) =>
                      setKioskState({
                        ...kioskState,
                        templatemeta: {
                          templatedata: {
                            ...kioskState?.templatemeta?.templatedata,
                            slicer: {
                              ...kioskState?.templatemeta?.templatedata.slicer,
                              source: e?.target?.value,
                            },
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Linkto"
                    multiline={false}
                    maxRows={5}
                    value={
                      kioskState?.templatemeta?.templatedata?.slicer?.linkto
                    }
                    onChange={(e) =>
                      setKioskState({
                        ...kioskState,
                        templatemeta: {
                          templatedata: {
                            ...kioskState?.templatemeta?.templatedata,
                            slicer: {
                              ...kioskState?.templatemeta?.templatedata.slicer,
                              linkto: e?.target?.value,
                            },
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <CustomCheckbox
                    kioskState={kioskState}
                    setKioskState={setKioskState}
                    chprops={{ label: "Visible?", type: "divider-isshow" }}
                  />
                </Grid>
              </>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Button Groups
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Buttons (links forward users to external links) related properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            {/* Button properties go here */}
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <DynamicFields
                type="buttons"
                kioskState={kioskState}
                setKioskState={setKioskState}
                removeField={removeButtonField}
              />
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button variant="outlined" onClick={AddNewButtonField}>
                  Add New
                </Button>
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Style sheet Properties
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Custom Style Sheet related properties( Theming)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {/* Css properties go here */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Theme"
                  multiline={true}
                  size="medium"
                  maxRows={10}
                  value={JSON.stringify(
                    kioskState?.templatemeta?.templatedata?.cssProps.theme,
                  )}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState.templatemeta.templatedata,
                          cssProps: {
                            ...kioskState.templatemeta.templatedata.cssProps,
                            theme: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Header Slider"
                  multiline={true}
                  size="medium"
                  maxRows={10}
                  value={JSON.stringify(
                    kioskState?.templatemeta?.templatedata?.cssProps
                      .headerslider,
                  )}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState.templatemeta.templatedata,
                          cssProps: {
                            ...kioskState.templatemeta.templatedata.cssProps,
                            headerslider: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Header Video"
                  multiline={true}
                  size="medium"
                  maxRows={10}
                  value={JSON.stringify(
                    kioskState?.templatemeta?.templatedata?.cssProps
                      .headervideo,
                  )}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState.templatemeta.templatedata,
                          cssProps: {
                            ...kioskState.templatemeta.templatedata.cssProps,
                            headervideo: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Divider"
                  multiline={true}
                  size="medium"
                  maxRows={10}
                  value={JSON.stringify(
                    kioskState.templatemeta?.templatedata?.cssProps.slicer,
                  )}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState.templatemeta.templatedata,
                          cssProps: {
                            ...kioskState.templatemeta.templatedata.cssProps,
                            slicer: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Button Group"
                  multiline={true}
                  size="medium"
                  maxRows={10}
                  value={JSON.stringify(
                    kioskState?.templatemeta?.templatedata?.cssProps.buttongrp,
                  )}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState.templatemeta.templatedata,
                          cssProps: {
                            ...kioskState.templatemeta.templatedata.cssProps,
                            buttongrp: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Grid container justifyContent="center">
        <Grid>
          <Button
            variant="outlined"
            style={{ margin: "2em" }}
            color="primary"
            onClick={submitForm}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GenericUiconfig;
