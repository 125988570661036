import styled from "styled-components";
import { TFormState } from "../../globalprops";
import SkeletonSelector from "../skeletons";
import {
  GenericTemplate,
  ImageBackground,
  Advanced,
  DraggableTemplate,
  HorizontalTemplate,
} from "./templates";
const TemplateMakerWrapper = styled.div``;
const TemplateMaker = ({ templatedata }: TFormState) => {
  if (templatedata?.templatename === "generic") {
    return (
      <TemplateMakerWrapper>
        <GenericTemplate templatedata={templatedata} />
      </TemplateMakerWrapper>
    );
  } else if (templatedata?.templatename === "imagebackground") {
    return (
      <TemplateMakerWrapper>
        <ImageBackground templatedata={templatedata} />
      </TemplateMakerWrapper>
    );
  } else if (templatedata?.templatename === "advanced") {
    return (
      <TemplateMakerWrapper>
        <Advanced templatedata={templatedata} />
      </TemplateMakerWrapper>
    );
  } else if (templatedata?.templatename === "draggabletemplate") {
    return (
      <TemplateMakerWrapper>
        <DraggableTemplate templatedata={templatedata} />
      </TemplateMakerWrapper>
    );
  } else if (templatedata?.templatename === "horizontal") {
    return (
      <TemplateMakerWrapper>
        <HorizontalTemplate templatedata={templatedata} />
      </TemplateMakerWrapper>
    );
  } else {
    return <SkeletonSelector type="kiosk" />;
  }
};

export default TemplateMaker;
