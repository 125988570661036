import styled from "styled-components";
import { TButton, TFormState } from "../../../globalprops";
const VideoHeaderComponentWrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  /* .header {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
    }
    .slicer {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
    } */
  .buttongrps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    justify-items: center;
    padding: 1em;
  }

  /* @media (min-width: 1080px) {
    min-height: 100vh;
    .header {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
    }
    .slicer {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
    }
  }


  @media (min-width: 1095px) {
    background-color: white !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
  }
  @media (min-width: 1100px) {
    background-color: white !important;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
  }
  @media (max-width: 900px) {
    background-color: white !important;
    min-height: 100vh !important;
    width: 100vw !important;
    position: absolute;
    top: 20%;
    left: 20%;
    transform: translate(-50%, -50%) scale(0.35);
  }
  @media (max-width: 850px) {
    background-color: white !important;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 38%;
    left: 40%;
    transform: translate(-50%, -50%) scale(0.75);
  } */

  /* @media (min-width: 900px) and (max-width: 1095px){ 
    background-color:white !important;
    min-height: 100vh !important;
    width: 100vw !important;
    position: absolute;
            top: 20%;
            left: 20%;
            transform: translate(-50%, -50%) scale(0.35  );
    };
    @media (min-width: 850px)and (max-width: 900px){ 
    background-color:white !important;
    height: 100vh;
    width: 100vw;
    position: absolute;
            top: 38%;
            left: 40%;
            transform: translate(-50%, -50%) scale(0.75 );
    }; */
`;

const VideoHeaderComponent = ({ templatedata }: TFormState) => {
  if (
    templatedata?.header &&
    templatedata?.header.videoData
    //&&templatedata?.header.videoData[0].linkto
  ) {
    return (
      <VideoHeaderComponentWrapper style={templatedata?.cssProps?.theme}>
        <a
          className="header"
          href={
            templatedata?.header?.videoData[0]?.linkto
              ? templatedata?.header?.videoData[0]?.linkto
              : "#"
          }
          style={templatedata?.cssProps?.headervideo}
        >
          <iframe
            style={templatedata?.cssProps?.headervideo}
            src={templatedata?.header.videoData[0].source}
            width={templatedata?.cssProps?.headervideo?.width}
            height={templatedata?.cssProps?.headervideo?.height}
            frameBorder="0"
          ></iframe>
        </a>
        {templatedata?.slicer?.status ? (
          <div className="slicer" style={templatedata?.cssProps?.slicer}>
            <a href={templatedata?.slicer?.linkto}>
              <img
                src={templatedata?.slicer?.source}
                alt={"slicer"}
                height={templatedata?.cssProps?.slicer?.height}
                width={templatedata?.cssProps?.slicer?.width}
              />
            </a>
          </div>
        ) : (
          ""
        )}
        <div className="buttongrps" style={templatedata?.cssProps?.buttongrp}>
          {templatedata?.buttons
            ?.sort((obj1: any, obj2: any) => obj1?.order - obj2?.order)
            .map((item: TButton, key) => {
              return (
                <a href={item?.linkto} key={key} style={item.css}>
                  {/* <div style={{background:`url("${item?.imagesrc}")`,backgroundSize:'cover',minWidth:'200px',minHeight:'200px' }}></div> */}
                  <img
                    src={item?.imagesrc}
                    alt={"button-" + key}
                    height={
                      item?.css?.height
                        ? item?.css?.height
                        : templatedata?.cssProps?.buttongrp.height
                    }
                    width={
                      item?.css?.width
                        ? item?.css?.width
                        : templatedata?.cssProps?.buttongrp.width
                    }
                  />
                </a>
              );
            })}
        </div>
      </VideoHeaderComponentWrapper>
    );
  } else {
    return <></>;
  }
};

export default VideoHeaderComponent;
