import CarouselHeader from "../../muicarousel/carousel.component";
import { TButton, TFormState } from "../../../globalprops";
import styled from "styled-components";

const SliderHeaderComponentWrapper = styled.div`
  //height: 100%;
  // width: 100%;
  //min-height: 100vh;
  /* overflow: visible !important; */
  //.header {
  //height: 100%;
  //width: 100%;
  /* min-height:950px; */
  /* width: 98%;
    margin-left: 0.5em;
    padding: 0.5em;
    background-color: #343a40; */
  /* overflow: visible !important; */
  //}
  /* min-height: 100vh;
  width: 100vw;
  .buttongrps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    justify-items: center;
    padding: 1em;
  } */
  /* @media (min-width: 1080px) {
    min-height: 100vh;
  }
  @media (min-width: 1095px) {
    background-color: white !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
  }
  @media (min-width: 1100px) {
    background-color: white !important;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
  }
  @media (max-width: 900px) {
    background-color: white !important;
    min-height: 100vh !important;
    width: 100vw !important;
    position: absolute;
    top: 20%;
    left: 20%;
    transform: translate(-50%, -50%) scale(0.35);
  }
  @media (max-width: 850px) {
    background-color: white !important;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 38%;
    left: 40%;
    transform: translate(-50%, -50%) scale(0.75);
  } */
`;

const SliderHeaderComponent = ({ templatedata }: TFormState) => {
  return (
    <SliderHeaderComponentWrapper style={templatedata?.cssProps?.theme}>
      {/* <div className="header" style={templatedata?.cssProps?.header}> */}
      <div className="header" style={templatedata?.cssProps?.headerslider}>
        <CarouselHeader
          header={templatedata?.header}
          cssProps={templatedata?.cssProps}
        />
      </div>
      {templatedata?.slicer?.status ? (
        <div className="slicer" style={templatedata?.cssProps?.slicer}>
          <a href={templatedata?.slicer?.linkto}>
            <img
              src={templatedata?.slicer?.source}
              alt={"slicer"}
              height={templatedata?.cssProps?.slicer?.height}
              width={templatedata?.cssProps?.slicer?.width}
            />
          </a>
        </div>
      ) : (
        ""
      )}
      <div className="buttongrps" style={templatedata?.cssProps?.buttongrp}>
        {templatedata?.buttons
          ?.sort((obj1: any, obj2: any) => obj1?.order - obj2?.order)
          .map((item: TButton, key) => {
            // console.log(item)
            return (
              // style={{backgroundImage:`url("${item.imagesrc}")`,height:item.css.height,width:item.css.width}}
              <a href={item.linkto} key={key}>
                <img
                  src={item.imagesrc}
                  alt={"button-" + key}
                  // height={
                  //   item?.css?.height
                  //     ? item?.css?.height
                  //     : templatedata?.cssProps?.buttongrp.height
                  // }
                  // width={
                  //   item?.css?.width
                  //     ? item?.css?.width
                  //     : templatedata?.cssProps?.buttongrp.width
                  // }
                  style={item?.css}
                />
              </a>
            );
          })}
      </div>
    </SliderHeaderComponentWrapper>
  );
};

export default SliderHeaderComponent;
