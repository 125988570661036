import { Link } from "react-router-dom";
import { TFormState } from "../../../globalprops";

const ImageBackground = ({ templatedata }: TFormState) => {
  return (
    <Link to={templatedata?.imagebackground?.linkto || "#"}>
      <div
        style={{
          background: `url(${templatedata?.imagebackground?.imagesource}) no-repeat center center fixed`,
          height: templatedata?.imagebackground?.height, //"100vh",
          width: templatedata?.imagebackground?.width, //"100vw",
          objectFit: "fill",
          objectPosition: "bottom",
          backgroundSize: "cover",
          ...templatedata?.imagebackground?.css,
        }}
      ></div>
    </Link>
  );
};

export default ImageBackground;
