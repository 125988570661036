import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

const StandardDropzone = ({
  getUploadParams,
  handleChangeStatus,
  handleSubmit, // uploadstatus,
}: any) => {
  // specify upload params and url for your files
  //console.log("---------------" + uploadstatus);

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      inputContent={
        "Drag Files or Click to Browse (Max upload size:2MB). It is highly advised to keep your asset sizes at KB level"
      }
      styles={{
        dropzone: {
          minHeight: 200,
          maxHeight: 250,
          fontSize: "1rem",
          color: "red",
        },
      }}
      maxSizeBytes={2000000} //2MB
      //validate={}
    />
  );
};

export default StandardDropzone;
