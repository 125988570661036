import axios from "axios";
import { Itemplates, IKiosk } from "../globalprops";
const { REACT_APP_api_root } = process.env;
import { getAuth } from "../hooks/useAuth";
const axiosInstance = axios.create({
  baseURL: REACT_APP_api_root,
  // timeout: 8000,
  headers: { Authorization: getAuth() },
});

//KIOSKS
export const getkiosks = async () => {
  let kiosks: IKiosk[] = [];
  let results = null;
  try {
    results = await axiosInstance.get("/getkiosks");
    kiosks = results?.data?.results;
  } catch (e) {
    Error(e as string);
  }
  return kiosks;
};
export const getactivekiosks = async () => {
  let kiosks: IKiosk[] = [];
  let results = null;
  try {
    results = await axiosInstance.get("/getactivekiosks");
    kiosks = results?.data?.results;
  } catch (e) {
    Error(e as string);
  }
  return kiosks;
};

export const getkioskByName = async (kioskName: string): Promise<IKiosk> => {
  let kiosk: IKiosk = {
    id: -1,
    kioskname: "",
    templatemeta: {
      templatedata: {
        cssProps: {
          theme: {},
          headerslider: {},
          headervideo: {},
          slicer: {},
          buttongrp: {},
        },
      },
    },
    templatename: "",
    kioskwidth: "",
    kioskheight: "",
    created_at: "",
    modified_at: "",
    isactive: false,
  };
  let result = null;
  try {
    result = await axiosInstance.get(`/getkioskByName?kioskname=${kioskName}`);
    if (result?.data?.results && result?.data?.results.length > 0) {
      kiosk = result?.data?.results[0];
    } else {
      kiosk = result?.data?.results;
    }
  } catch (e) {
    Error(e as string);
  }
  const k = JSON.parse(JSON.parse(JSON.stringify(kiosk?.templatemeta)));
  kiosk.templatemeta = k;
  //console.log("Kiosk=", kiosk);
  return kiosk;
};

export const getkioskNames = async () => {
  let kiosks: IKiosk[] = [];
  let results = null;
  try {
    results = await axiosInstance.get("/getkiosks");
    kiosks = results?.data?.results;
    if (kiosks.length > 0 || kiosks !== null || kiosks !== undefined) {
      return kiosks?.map((kiosk) => {
        return { key: kiosk?.kioskname, value: kiosk?.kioskname };
      });
    }
  } catch (e) {
    Error(e as string);
  }

  return [{ key: "error", value: "error" }];
};

export const addkiosk = async (
  kiosk: IKiosk,
  IskioskConfigCopychecked: boolean,
  selectedKioskCopyConfigFrom: string,
) => {
  let results = null;
  try {
    results = await axiosInstance.post("/addkiosk", {
      kiosk,
      IskioskConfigCopychecked,
      selectedKioskCopyConfigFrom,
    });
    console.log(results);
  } catch (e) {
    Error(e as string);
  }
  if (results?.data?.results === "The kiosk name exist in database!") {
    return false;
  }
  return true;
};

export const updatekioskTemplate = async (kiosk: IKiosk): Promise<any> => {
  let results = null;
  const data = { templatemeta: kiosk.templatemeta, id: kiosk?.id };
  try {
    results = await axiosInstance.put("/updatekiosktemplate", data);
    console.log(results);
  } catch (e) {
    Error(e as string);
  }
  return results;
};

export const deletekiosk = async (id: any) => {
  const data: any = { data: { id: id } };
  let result = null;
  try {
    result = await axiosInstance.delete("/deletekiosk", data);
  } catch (e) {
    Error(e as string);
  }
  return result;
};

//KIOSK TEMPLATES

export const getkiosktemplates = async (): Promise<any> => {
  let kiosktemplates: Itemplates[] = [];
  let results = null;
  try {
    results = await axiosInstance.get("/getkiosktemplates");
    kiosktemplates = results?.data?.results;
    if (
      kiosktemplates.length > 0 ||
      kiosktemplates !== null ||
      kiosktemplates !== undefined
    ) {
      return kiosktemplates?.map((template) => {
        return {
          key: template?.templatename,
          value: template?.templatename,
          templateDefaults: template?.templateDefaults,
        };
      });
    }
  } catch (e) {
    Error(e as string);
  }

  return [{ key: "error", value: "error" }];
};

//Upload to S3. This will be moved to lambda. for now test it here
// const client = new S3Client({
//   region: REACT_APP_S3region,
//   credentials: { accessKeyId: accessKeyId, secretAccessKey: secretAccessKey },
// });

//this entire loading to S3 will be handled in Lambda
export const uploadToS3 = async (
  files: any,
  allFiles: any,
  meta: { kioskname: string; templatepart: string },
) => {
  //change the file name with the timestamp
  //create a record in database then upload through Lambda
  console.log("uploading to S3");

  try {
    files.map(async (item: any, index: number) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[index].file);
      reader.onload = async function () {
        //console.log(reader.result);
        await axiosInstance.post("/uploadToS3", {
          meta,
          index,
          item,
          file: reader.result,
        });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

      //AWSS3_PUT(files, allFiles, index);

      //await axiosInstance.post("/uploadToS3", {item,file:getBase64(files[index].file)});
      //console.log(getBase64(files[index].file))
    });
  } catch (e) {
    Error(e as string);
  }
};

//replace image in s3
export const replaceS3AssetwithThenewOne = async (
  file: any,
  imagename: string,
  id: number,
) => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      //console.log(reader.result);
      await axiosInstance.put("/updateS3AssetMetaIntoDB", {
        imagename,
        id,
        file: reader.result,
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  } catch (e) {
    Error(e as string);
  }
};

type TImage = {
  id: string;
  kioskname: string;
  imagename: string;
  alt: string;
  meta: string;
  created_at: string;
  modified_at: string;
  isactive: number;
};
export const getassetsByKiosk = async (
  kioskname: string,
): Promise<TImage[]> => {
  let images: TImage[] = [];
  let results = null;
  if (kioskname === "" || kioskname === undefined || kioskname === null) {
    kioskname = "no-kiosk-name-is-specified";
  }

  try {
    results = await axiosInstance.get(
      `/listS3assetsByKiosk?kioskname=${kioskname}`,
    );
    images = results?.data?.results;

    if (images.length > 0 && images !== null && images !== undefined) {
      return images;
    }
  } catch (e) {
    Error(e as string);
  }
  //console.log(images.length)
  // if(images.length===0){
  //  return [{id:'null',kioskname:'null',alt:'null',created_at:'null',imagename:'null',isactive:0,meta:'null',modified_at:'null'}];
  // }
  return images;
};

export const deleteS3Asset = async (id: any) => {
  const data: any = { data: { id: id } };
  let result = null;
  try {
    result = await axiosInstance.delete("/deleteS3AssetMetaIntoDB", data);
  } catch (e) {
    Error(e as string);
  }
  return result;
};
