import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const FormDialog = ({
  state,
  handleClose,
  handleRemove,
  //password,
  setPassword,
}: any) => {
  return (
    <div>
      <Dialog open={state} onClose={handleClose}>
        <DialogTitle>Remove</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To delete the Kiosk input the password
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password-for-delete"
            label="password"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setPassword(e?.target?.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRemove}>Remove</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
