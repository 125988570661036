import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "styled-components";
import Link from "@mui/material/Link";
import SkeletonSelector from "../skeletons";
import { deleteS3Asset, replaceS3AssetwithThenewOne } from "../../apis";
import AlertDialog from "../muialertDialog";
const { REACT_APP_S3assets } = process.env;

type item = {
  id: string;
  kioskname: string;
  imagename: string;
  alt: string;
  meta: string;
  created_at: string;
  modified_at: string;
  isactive: number;
};
type items = {
  items: item[];
  isControlOn?: boolean;
  isUpdate?: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImageListWrapper = styled.div`
  .isdisabledLinks {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const StandardImageList = ({
  items,
  isControlOn,
  isUpdate,
  setIsUpdate,
}: items) => {
  const [IsDeleteImageDialogOpen, setIsDeleteImageDialogOpen] =
    useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>();
  const [imageUpdatePending, setImageUpdatePending] = useState<boolean>(false);

  const removeImage = async () => {
    console.log("removing image", itemToDelete);
    setIsAgree(true);
    await deleteS3Asset(itemToDelete?.id);
    setIsUpdate((prev) => !prev);
    setIsDeleteImageDialogOpen(false);
    setIsAgree(false);
  };

  const replaceImage = async (e: any, item: any) => {
    console.log("replace image", e, item);
    const file = e?.target?.files[0];
    console.log(file);
    await replaceS3AssetwithThenewOne(file, item?.imagename, item?.id);
    setImageUpdatePending(true);
    setTimeout(() => {
      setIsUpdate((prev) => !prev);
      setImageUpdatePending(false);
    }, 5000);
  };
  console.log(items);
  return (
    <ImageListWrapper>
      <AlertDialog
        description={"This will permanently delete the image from database"}
        title={"Image Delete"}
        isOpen={IsDeleteImageDialogOpen}
        handleClose={() => {
          setIsDeleteImageDialogOpen((prev) => !prev);
        }}
        isAgree={isAgree}
        onAgree={removeImage}
      />
      {
        //items.length===1 &&items[0]?.id==='null' ?'There is no asset to show for this Kiosk':
        items.length > 0 && items[0]?.id !== "null" ? (
          <ImageList
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
            cols={3}
            rowHeight={164}
          >
            {items.map((item: item) => (
              <ImageListItem
                key={`${REACT_APP_S3assets}/${item?.id}-${isUpdate}`}
              >
                <Link
                  href={
                    isControlOn
                      ? `${REACT_APP_S3assets}/${item?.imagename}`
                      : "#"
                  }
                  rel="noopener"
                  target={isControlOn ? "_blank" : ""}
                  className={imageUpdatePending ? "isdisabledLinks" : ""}
                >
                  <div>{isUpdate}</div>
                  {imageUpdatePending ? (
                    <p
                      style={{
                        position: "absolute",
                        top: "50%",
                        color: "white",
                        backgroundColor: "#303030",
                        width: "100%",
                      }}
                    >
                      Please Wait
                    </p>
                  ) : (
                    ""
                  )}
                  <img
                    src={`${REACT_APP_S3assets}/${
                      item.imagename
                    }?${Date.now()}`}
                    srcSet={`${REACT_APP_S3assets}/${
                      item.imagename
                    }?${Date.now()}`}
                    alt={item?.meta}
                    loading="lazy"
                    style={{
                      width: "164px",
                      height: "164px",
                      maxHeight: "100%",
                      minWidth: "100%",
                      objectFit: "cover",
                      verticalAlign: "bottom",
                    }}
                    // className={imageUpdatePending?'active-update':''}
                  />
                </Link>
                {isControlOn ? (
                  <div>
                    <Link
                      type="file"
                      // onClick={(e)=>replaceImage(e,item)}
                      // href="#"
                      // underline="hover"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        padding: "2px",
                        left: "3px",
                        color: "white",
                        backgroundColor: "#0000008f",
                        fontSize: "1rem",
                        borderRadius: "8px",
                      }}
                    >
                      <SettingsIcon />
                      <input
                        type="file"
                        onChange={(e) => replaceImage(e, item)}
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          padding: "2px",
                          left: "3px",
                          color: "white",
                          backgroundColor: "#0000008f",
                          fontSize: "1rem",
                          borderRadius: "8px",
                          opacity: "0",
                        }}
                      />
                    </Link>

                    {/* <Link
                onClick={copyClipboard}
                href={'#'+item.src}
                underline="hover"
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: "4em",
                  padding: "2px",
                  color: "white",
                  backgroundColor: "#0000008f",
                  fontSize: "1rem",
                  borderRadius: "8px",
                }}
              >
                <ContentCopyIcon/>
              </Link> */}
                    <Link
                      onClick={() => {
                        setIsDeleteImageDialogOpen(true);
                        setItemToDelete(item);
                      }} //{(e) => removeImage(e, item)}
                      href="#"
                      underline="hover"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        left: "8.1em",
                        padding: "2px",
                        color: "white",
                        backgroundColor: "#0000008f",
                        fontSize: "1rem",
                        borderRadius: "8px",
                      }}
                    >
                      <DeleteIcon />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <SkeletonSelector type="imagelist" />
        )
      }
    </ImageListWrapper>
  );
};

export default StandardImageList;
