import { Paper, Skeleton, Stack } from "@mui/material";
type TSkeleton = {
  type: string;
};
const SkeletonSelector = ({ type }: TSkeleton) => {
  switch (type) {
    case "table":
      return (
        <>
          <Stack spacing={1} style={{ padding: "1em" }}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Stack>
        </>
      );
      break;
    case "input":
      return <Skeleton variant="rectangular" width={"100%"} height={"20%"} />;
      break;
    case "imagelist":
      return (
        <>
          <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
          {" Images are being Loaded"}
          <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        </>
      );
    case "kiosk":
      return (
        <>
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rectangular" width={1820} height={800} />
            <Skeleton variant="rectangular" width={1820} height={50} />
            <Skeleton variant="rounded" width={1820} height={1100} />
          </Stack>
        </>
      );
      break;
    case "form":
      return (
        <>
          <Paper
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em",
            }}
          >
            {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{display:'flex',alignSelf:'left',width:'200px'}}/> */}
            {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{display:'flex',alignSelf:'right',width:'200px'}}/> */}
            <Skeleton variant="rectangular" width={"20%"} height={50} />
            <Skeleton variant="rectangular" width={"10%"} height={50} />
            <Skeleton variant="rectangular" width={"20%"} height={50} />
          </Paper>
          <Stack spacing={1} style={{ padding: "1em" }}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Stack>
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "1em",
            }}
          >
            <Skeleton variant="rectangular" width={"10%"} height={50} />
          </Paper>
        </>
      );
      break;
    default:
      return <Skeleton variant="rectangular" width={"100%"} height={"50%"} />;
      break;
  }
};

export default SkeletonSelector;
