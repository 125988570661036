import { Grid, TextField } from "@mui/material";
import { IKiosk } from "../../globalprops";
import CustomCheckbox from "../../components/checkbox";
import { JsonParser } from "../../globalhelper";
type TData = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
  bannerside: "left" | "right";
};
const BannerConfigHorizontal = ({
  kioskState,
  setKioskState,
  bannerside,
}: TData) => {
  {
    return bannerside === "left" ? (
      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <>
          {/* Header Image*/}
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="Image Source"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerLeft?.image?.src
              }
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerLeft: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerLeft,
                          image: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.horizontalTemplate?.bannerLeft?.image,
                            src: e?.target?.value,
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CustomCheckbox
              kioskState={kioskState}
              setKioskState={setKioskState}
              chprops={{ label: "Visible", type: "left-bannerimage-isvisible" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="CSS Properties"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={JSON.stringify(
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerLeft?.image?.css,
              )}
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerLeft: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerLeft,
                          image: {
                            ...kioskState?.templatemeta?.templatedata
                              .horizontalTemplate?.bannerLeft?.image,
                            css: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
        </>
        <>
          {/* Header Video*/}
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="Video Source"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerLeft?.video?.src
              }
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerLeft: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerLeft,
                          video: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.horizontalTemplate?.bannerLeft?.video,
                            src: e?.target?.value,
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CustomCheckbox
              kioskState={kioskState}
              setKioskState={setKioskState}
              chprops={{ label: "Visible", type: "left-bannervideo-isvisible" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="CSS Properties"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={JSON.stringify(
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerLeft?.video?.css,
              )}
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerLeft: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerLeft,
                          video: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.horizontalTemplate?.bannerLeft?.video,
                            css: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    },
                  },
                  // ...kioskState,
                  // templatemeta: {
                  //   templatedata: {
                  //     ...kioskState?.templatemeta?.templatedata,
                  //     banner: {
                  //       ...kioskState?.templatemeta?.templatedata.banner,
                  //       video: {
                  //         ...kioskState?.templatemeta?.templatedata?.banner
                  //           ?.video,
                  //         css: JsonParser(e?.target?.value),
                  //       },
                  //     },
                  //   },
                  // },
                })
              }
            />
          </Grid>
        </>
      </Grid>
    ) : (
      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <>
          {/* Header Image*/}
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="Image Source"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerRight?.image?.src
              }
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerRight: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerRight,
                          image: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.horizontalTemplate?.bannerRight?.image,
                            src: e?.target?.value,
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CustomCheckbox
              kioskState={kioskState}
              setKioskState={setKioskState}
              chprops={{
                label: "Visible",
                type: "right-bannerimage-isvisible",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="CSS Properties"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={JSON.stringify(
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerRight?.image?.css,
              )}
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerRight: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerRight,
                          image: {
                            ...kioskState?.templatemeta?.templatedata
                              .horizontalTemplate?.bannerRight?.image,
                            css: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
        </>
        <>
          {/* Header Video*/}
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="Video Source"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerRight?.video?.src
              }
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerRight: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerRight,
                          video: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.horizontalTemplate?.bannerRight?.video,
                            src: e?.target?.value,
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CustomCheckbox
              kioskState={kioskState}
              setKioskState={setKioskState}
              chprops={{
                label: "Visible",
                type: "right-bannervideo-isvisible",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="CSS Properties"
              multiline={true}
              size="medium"
              maxRows={50000}
              value={JSON.stringify(
                kioskState?.templatemeta?.templatedata?.horizontalTemplate
                  ?.bannerRight?.video?.css,
              )}
              onChange={(e) =>
                setKioskState({
                  ...kioskState,
                  templatemeta: {
                    templatedata: {
                      ...kioskState?.templatemeta?.templatedata,
                      horizontalTemplate: {
                        ...kioskState?.templatemeta?.templatedata
                          ?.horizontalTemplate,
                        bannerRight: {
                          ...kioskState?.templatemeta?.templatedata
                            ?.horizontalTemplate?.bannerRight,
                          video: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.horizontalTemplate?.bannerRight?.video,
                            css: JsonParser(e?.target?.value),
                          },
                        },
                      },
                    },
                  },
                })
              }
            />
          </Grid>
        </>
      </Grid>
    );
  }
};

export default BannerConfigHorizontal;
