import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertDialog = ({
  description,
  title,
  isOpen,
  handleClose,
  onAgree,
  isAgree,
}: any) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isAgree
              ? "The selected asset is being deleted.Please Wait..."
              : description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isAgree ? (
            <>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={onAgree} autoFocus>
                Agree
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleClose} disabled>
                Disagree
              </Button>
              <Button onClick={onAgree} disabled>
                Agree
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
