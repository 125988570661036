import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
type TModal = {
  modalOpen: boolean;
  cellValues: any;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleModalClose: React.Dispatch<React.SetStateAction<string>>;
};
const BasicModal = ({
  modalOpen,
  handleModalClose,
  setModalOpen,
  cellValues,
}: TModal) => {
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) scale(0.3)",
          }}
        >
          <Button
            onClick={() => setModalOpen(false)}
            style={{
              position: "absolute",
              right: "-4%",
              top: "-1%",
              color: "red",
              transform: "scale(3.5)",
            }}
          >
            <CancelIcon />
          </Button>
          <Link
            to={"/kiosk/" + cellValues?.row?.kioskname}
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "black",
              color: "white",
              fontSize: "3em",
              padding: "1em",
            }}
            target={"_blank"}
          >
            {cellValues?.row?.kioskname}
          </Link>

          <iframe
            src={"/kiosk/" + cellValues?.row?.kioskname}
            style={{
              height: !isNaN(cellValues?.row?.kioskheight)
                ? cellValues?.row?.kioskheight + "px"
                : cellValues?.row?.kioskheight, //"1920px",
              width: !isNaN(cellValues?.row?.kioskheight)
                ? cellValues?.row?.kioskwidth + "px"
                : cellValues?.row?.kioskwidth, //"1080px",
              border: "6em solid black",
            }}
          ></iframe>
          {/* <>{console.log(cellValues)}</> */}
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Kiosk Preview
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <>Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            {console.log(cellValues)}</>
          </Typography> */}
          {/* <Button onClick={()=>setModalOpen(false)}>Close</Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
