import { Grid, Button, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactElement, Fragment } from "react";
import { IKiosk, TAssetJS } from "../../globalprops";

type fields = {
  removeField: any;
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
};

const DynamicAdvancedTemplateJSFields = ({
  kioskState,
  removeField,
}: fields): ReactElement => {
  let fieldsarray: TAssetJS[] = [];
  fieldsarray =
    kioskState?.templatemeta?.templatedata.fullpage?.externalJS || [];

  const onChangeHandler = (e: any, index: any, inputType: string) => {
    fieldsarray?.map((field: TAssetJS, i: number) => {
      if (i === index) {
        switch (inputType) {
          case "src":
            field.src = e?.target?.value;
            break;
          case "crossorigin":
            field.crossorigin = e?.target?.value;
            break;
          case "integrity":
            field.integrity = e?.target?.value;
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <>
      {fieldsarray?.map((field: TAssetJS, index: number) => {
        return (
          <Fragment key={field.key}>
            <Grid item xs={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="Source"
                multiline={false}
                defaultValue={field?.src}
                size="medium"
                fullWidth
                maxRows={5}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, "src")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="Crossorigin"
                multiline={false}
                defaultValue={field?.crossorigin}
                fullWidth
                maxRows={5}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, "crossorigin")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-multiline-flexible"
                label="Integrity"
                multiline={false}
                type="text"
                defaultValue={field?.integrity}
                fullWidth
                maxRows={5}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, "ingegrity")}
              />
            </Grid>
            <Grid item xs={1}>
              {index !== 0 ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeField(index)}
                >
                  <DeleteIcon style={{ fontSize: "3em" }} />
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Fragment>
        );
      })}
    </>
  );
};

export default DynamicAdvancedTemplateJSFields;
