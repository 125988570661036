import styled from "styled-components";
import CarouselHeader from "../../muicarousel/carousel.component";
import { TFormState } from "../../../globalprops";
const HorizontalHeaderComponentWrapper = styled.div`
  /* min-height: 100vh;
  width: 100vw;
  .buttongrps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    justify-items: center;
    padding: 1em;
  } */
`;

const HorizontalHeader = ({ templatedata }: TFormState) => {
  if (
    templatedata?.header &&
    templatedata?.header?.videoData &&
    templatedata?.header?.isvideo
  ) {
    return (
      <HorizontalHeaderComponentWrapper>
        <a
          className="header"
          href={
            templatedata?.header?.videoData[0]?.linkto
              ? templatedata?.header?.videoData[0]?.linkto
              : "#"
          }
          style={templatedata?.cssProps?.headervideo}
        >
          <iframe
            style={templatedata?.cssProps?.headervideo}
            src={templatedata?.header.videoData[0].source}
            width={templatedata?.cssProps?.headervideo?.width}
            height={templatedata?.cssProps?.headervideo?.height}
            frameBorder="0"
          ></iframe>
        </a>
      </HorizontalHeaderComponentWrapper>
    );
  } else {
    return (
      <div className="header" style={templatedata?.cssProps?.headerslider}>
        <CarouselHeader
          header={templatedata?.header}
          cssProps={templatedata?.cssProps}
        />
      </div>
    );
  }
};

export default HorizontalHeader;
