import { Grid, Button, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactElement, Fragment } from "react";
import { IKiosk } from "../../globalprops";

type field = {
  source: string;
  linkto: string;
  order: number;
  key: number;
};
type fields = {
  removeField: any;
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
};

const DynamicHeaderFields = ({
  removeField,
  kioskState,
  setKioskState,
}: fields): ReactElement => {
  let fieldsarray: any = [];
  if (kioskState?.templatemeta?.templatedata.header?.isSlider) {
    fieldsarray = kioskState?.templatemeta?.templatedata.header?.sliderData;
  } else {
    fieldsarray = kioskState?.templatemeta?.templatedata.header?.videoData;
  }
  const onChangeHandler = (
    e: any,
    index: any,
    field: field,
    inputType: string,
  ) => {
    fieldsarray.map((field: any, i: number) => {
      if (i === index) {
        switch (inputType) {
          case "source":
            field.source = e?.target?.value;
            break;
          case "linkto":
            field.linkto = e?.target?.value;
            break;
          case "order":
            field.order = e?.target?.value;
            break;
          default:
            break;
        }
      }
    });
    // setFormState({...formState.templatedata,[...fieldsarray]});
    if (kioskState?.templatemeta?.templatedata.header?.isSlider) {
      //fieldsarray=formState?.templatedata.header?.sliderData;
      //setFormState({...formState.templatedata,[...fieldsarray]});
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState?.templatemeta?.templatedata,
            header: {
              ...kioskState?.templatemeta.templatedata.header,
              isSlider: true,
              isvideo: false,
            },
          },
        },
      });
    } else {
      //fieldsarray=formState?.templatedata.header?.videoData;
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState?.templatemeta?.templatedata,
            header: {
              ...kioskState?.templatemeta?.templatedata?.header,
              isSlider: false,
              isvideo: true,
            },
          },
        },
      });
    }
  };

  return (
    <>
      {fieldsarray?.map((field: any, index: number) => {
        return (
          <Fragment key={field.key}>
            <Grid item xs={5}>
              <TextField
                id="outlined-multiline-flexible"
                label="Source"
                multiline={false}
                defaultValue={field?.source}
                size="medium"
                maxRows={50000}
                onChange={(e) => onChangeHandler(e, index, field, "source")}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="outlined-multiline-flexible"
                label="Linkto"
                multiline={false}
                defaultValue={field?.linkto}
                maxRows={5}
                onChange={(e) => onChangeHandler(e, index, field, "linkto")}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                id="outlined-multiline-flexible"
                label="Order"
                multiline={false}
                type="number"
                defaultValue={field?.order}
                maxRows={5}
                onChange={(e) => onChangeHandler(e, index, field, "order")}
              />
            </Grid>
            <Grid item xs={1}>
              {index !== 0 ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeField(index)}
                >
                  <DeleteIcon style={{ fontSize: "3em" }} />
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Fragment>
        );
      })}
    </>
  );
};

export default DynamicHeaderFields;
