import { Amplify, Auth } from "aws-amplify";
import awsExports from "../aws-exports.js";

Amplify.configure(awsExports);

interface ISignInUserSession {
  accessToken: Record<string, unknown>; //{}
  idToken: {
    jwtToken: string;
  };
  refreshToken: Record<string, unknown>; //{}
}
interface IUser {
  Session: string;
  attributes: { email: string };
  signInUserSession: ISignInUserSession;
}

export const Signin = async (username: string, password: string) => {
  let respond = null;
  try {
    if (localStorage.getItem("user")) {
      localStorage.clear();
    }
    respond = await Auth.signIn(username, password);
    localStorage.setItem("user", JSON.stringify(respond));
    window.location.href = "/home";
    respond = "";
  } catch (e: any) {
    console.log("error happened!", e);
    respond = e?.message ? e?.message : e;
  }
  return respond;
};

export const SignOut = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    window.location.href = "/";
  } catch (e) {
    console.log(e);
  }
};

export const isUserSignedIn = (): boolean => {
  const user = localStorage.getItem("user");
  try {
    if (user) {
      //check validity of JWT and if jwt is expired then signout. Check and change signout with refresh token in future
      if (!isValidSession()) {
        //const newSession=await Auth.currentAuthenticatedUser()
        //localStorage.removeItem("user");
        //localStorage.setItem("user",newSession);
        SignOut();
      }
      return true;
    }
  } catch (e) {
    localStorage.clear();
  }

  return false;
};

export const getJWT = (): any => {
  const storage = localStorage.getItem("user");
  if (typeof storage === "string") {
    const user: IUser = JSON.parse(storage);
    if (user) {
      return user?.signInUserSession?.idToken?.jwtToken;
    } else {
      new Error("no jwt available. Sign in");
    }
  }
};

export const isValidSession = (): boolean => {
  const JWT = getJWT();
  let isExpired = true;
  if (JWT) {
    const jwtPayload = JSON.parse(window.atob(JWT.split(".")[1]));
    isExpired = Date.now() >= jwtPayload.exp * 1000;
  }
  return !isExpired;
};

export const getUserEmail = () => {
  const storage = localStorage.getItem("user");
  if (typeof storage === "string") {
    const user: IUser = JSON.parse(storage);
    if (user) {
      return user.attributes.email;
    } else {
      new Error("no user available. Sign in");
    }
  }
};

export const getAuth = (): string => {
  const jwt: string = getJWT() ? getJWT() : "";
  return jwt;
};
