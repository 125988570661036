import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { TTemplatedata } from "../../globalprops";

const CarouselHeader = ({ cssProps, header }: TTemplatedata) => {
  return (
    <Carousel
      interval={cssProps?.headerslider?.interval}
      showIndicators={cssProps?.headerslider?.indicators}
      autoPlay={true}
      infiniteLoop={true}
    >
      {header?.sliderData
        ?.sort((obj1: any, obj2: any) => obj1?.order - obj2?.order)
        .map((item, i) => (
          <Item key={i} slide={item} cssProps={cssProps} id={i} />
        ))}
    </Carousel>
  );
};

const Item = ({ slide, cssProps, id }: any) => {
  return (
    <div onClick={() => (location.href = slide?.linkto)}>
      <p>{slide?.caption}</p>
      <a href={slide?.linkto}>
        {" "}
        <img
          src={slide?.source}
          alt={"slide-" + id}
          height={cssProps.slider.height ? cssProps.slider.height : "100%"}
          width={cssProps.slider.width ? cssProps.slider.width : "100%"}
        />
      </a>
    </div>
  );
};

export default CarouselHeader;

/*import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import { TTemplatedata } from "../../globalprops";
const CarouselHeader = ({ cssProps, header }: TTemplatedata) => {
  return (
    <Carousel
      interval={cssProps?.headerslider?.interval}
      indicators={cssProps?.headerslider?.indicators}
    >
      {header?.sliderData
        ?.sort((obj1: any, obj2: any) => obj1?.order - obj2?.order)
        .map((item, i) => (
          <Item key={i} slide={item} cssProps={cssProps} id={i} />
        ))}
    </Carousel>
  );
};

const Item = ({ slide, cssProps, id }: any) => {
  return (
    // style={{backgroundColor:cssProps.theme.backgroundColor,}}
    // <Paper style={cssProps.header}>
    <Paper style={{ backgroundColor: cssProps.theme.backgroundColor }}>
      <p>{slide?.caption}</p>
      <a href={slide?.linkto}>
        {" "}
        <img
          src={slide?.source}
          alt={"slide-" + id}
          height={cssProps.slider.height ? cssProps.slider.height : "100%"}
          width={cssProps.slider.width ? cssProps.slider.width : "100%"}
        />
      </a>
    </Paper>
  );
};

export default CarouselHeader;
*/
