import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
type keyvalue = {
  key: string;
  value: string;
};
type props = {
  label: string;
  items: keyvalue[];
  selectedValue: string;
  handleChange: (e: any) => void;
};

const BasicSelect = ({ label, items, selectedValue, handleChange }: props) => {
  let disabled = true;
  items?.length < 1 || (items.length == 1 && items[0].key === "")
    ? (disabled = true)
    : (disabled = false);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={label + "-simple-select-label"}>{label}</InputLabel>
        <Select
          labelId={label + "-simple-select-label"}
          id={label + "-simple-select"}
          value={selectedValue}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {items?.map((item, index) => (
            <MenuItem key={index} value={item.key}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
