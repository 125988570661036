import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isUserSignedIn } from "../../hooks/useAuth";
import Login from "../login";
import Navbar from "../../components/navbar";
import { IKiosk } from "../../globalprops";
import { getkioskByName, updatekioskTemplate } from "../../apis";
import { IsValidKioskState } from "../../globalhelper";
import SkeletonSelector from "../../components/skeletons";
import AdvancedUIconfig from "./advancedUIconfig.component";
import GenericUiconfig from "./genericUIconfig.component";
import ImagebackgroundUIconfig from "./imagebackgroundUIconfig.component";
import BasicModal from "../../components/modal";
import HorizontalUiconfig from "./horizontalUIconfig.component";
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.h3,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: "center",
}));

const UpdateKiosk = () => {
  //get this part from db by template and set formState here in useEffect
  const [kioskState, setKioskState] = useState<IKiosk>({
    id: 0,
    kioskname: "",
    templatemeta: {
      templatedata: {
        cssProps: {
          headerslider: {},
          headervideo: {},
          buttongrp: {},
          slicer: {},
          theme: {},
        },
      },
    },
    templatename: "",
    kioskwidth: "",
    kioskheight: "",
    created_at: "",
    modified_at: "",
    isactive: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  type TCellvals = {
    row: IKiosk;
  };
  const [cellValues, setCellValues] = useState<TCellvals>({
    row: {
      id: 0,
      kioskwidth: "",
      kioskheight: "",
      kioskname: "",
      created_at: "",
      modified_at: "",
      templatename: "",
      isactive: false,
      templatemeta: {
        templatedata: {
          cssProps: {
            buttongrp: {},
            headerslider: {},
            headervideo: {},
            slicer: {},
            theme: {},
          },
        },
      },
    },
  });

  const submitForm = async () => {
    console.log(`Kiosk State:`, kioskState);
    IsValidKioskState(kioskState);
    await updatekioskTemplate(kioskState);
  };
  useEffect(() => {
    const asyncFunc = async () => {
      const kioskName = location.pathname.split("/")[2];
      const kiosk = await getkioskByName(kioskName);
      setKioskState(kiosk);
      setCellValues({ row: kiosk });
    };
    asyncFunc();
  }, []);

  return (
    <>
      {isUserSignedIn() ? (
        <>
          <Navbar />
          <Div>Update</Div>
          <Box sx={{ m: 1 }} />
          <BasicModal
            modalOpen={modalOpen}
            handleModalClose={handleModalClose}
            setModalOpen={setModalOpen}
            cellValues={cellValues}
          />
          {kioskState?.templatemeta?.templatedata?.templatename ===
          "generic" ? (
            <GenericUiconfig
              kioskState={kioskState}
              setKioskState={setKioskState}
              submitForm={submitForm}
              setModalOpen={setModalOpen}
            />
          ) : kioskState?.templatemeta?.templatedata?.templatename ===
            "advanced" ? (
            <AdvancedUIconfig
              kioskState={kioskState}
              setKioskState={setKioskState}
              submitForm={submitForm}
              setModalOpen={setModalOpen}
            />
          ) : kioskState?.templatemeta?.templatedata?.templatename ===
            "imagebackground" ? (
            <ImagebackgroundUIconfig
              kioskState={kioskState}
              setKioskState={setKioskState}
              submitForm={submitForm}
              setModalOpen={setModalOpen}
            />
          ) : kioskState?.templatemeta?.templatedata?.templatename ===
            "horizontal" ? (
            <HorizontalUiconfig
              kioskState={kioskState}
              setKioskState={setKioskState}
              submitForm={submitForm}
              setModalOpen={setModalOpen}
            />
          ) : (
            // "No Template configuration UI Found for this template"
            <SkeletonSelector type="form" />
          )}
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default UpdateKiosk;
