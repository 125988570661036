import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IKiosk } from "../../globalprops";

type IAdvancedUIconfigProps = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
  submitForm: any;
  setModalOpen: React.Dispatch<React.SetStateAction<any>>;
};
const ImagebackgroundUIconfig = ({
  kioskState,
  setKioskState,
  submitForm,
  setModalOpen,
}: IAdvancedUIconfigProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container maxWidth="lg">
      <Grid container style={{ paddingBottom: "2em" }}>
        <Grid item xs={2}>
          <TextField
            label="Kiosk Name"
            color="primary"
            disabled
            value={kioskState?.kioskname}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Height"
            color="primary"
            disabled
            // onChange={(e) =>
            //   setKioskState({
            //     ...kioskState,
            //     kioskheight:e?.target?.value
            //   })
            // }
            defaultValue={kioskState?.kioskheight}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Width"
            color="primary"
            disabled
            // onChange={(e) =>
            //   setKioskState({
            //     ...kioskState,
            //     kioskwidth:e?.target?.value
            //   })
            // }
            defaultValue={kioskState?.kioskwidth}
          />
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={() => setModalOpen((prev: boolean) => !prev)}
          >
            Preview
            <RemoveRedEyeIcon style={{ paddingLeft: "15px" }} />
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <TextField
            label="Template Name"
            color="primary"
            disabled
            value={kioskState?.templatemeta?.templatedata?.templatename}
          />
        </Grid>
      </Grid>
      {/* <Accordion> */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Image Background
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Image (Image body content goes here) related properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <TextField
                  label="Image Source"
                  fullWidth
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          imagebackground: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.imagebackground,
                            imagesource: e?.target?.value,
                          },
                        },
                      },
                    })
                  }
                  defaultValue={
                    kioskState?.templatemeta?.templatedata?.imagebackground
                      ?.imagesource
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Link To"
                  fullWidth
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          imagebackground: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.imagebackground,
                            linkto: e?.target?.value,
                          },
                        },
                      },
                    })
                  }
                  defaultValue={
                    kioskState?.templatemeta?.templatedata?.imagebackground
                      ?.linkto
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Height"
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          imagebackground: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.imagebackground,
                            height: e?.target?.value,
                          },
                        },
                      },
                    })
                  }
                  defaultValue={
                    kioskState?.templatemeta?.templatedata?.imagebackground
                      ?.height
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Width"
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          imagebackground: {
                            ...kioskState?.templatemeta?.templatedata
                              ?.imagebackground,
                            width: e?.target?.value,
                          },
                        },
                      },
                    })
                  }
                  defaultValue={
                    kioskState?.templatemeta?.templatedata?.imagebackground
                      ?.width
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="CSS"
                  multiline
                  fullWidth
                  rows={5}
                  defaultValue={JSON.stringify(
                    kioskState?.templatemeta?.templatedata?.imagebackground
                      ?.css,
                  )}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          imagebackground: {
                            ...(kioskState?.templatemeta?.templatedata
                              ?.imagebackground || ""),
                            css: JSON.parse(e?.target?.value),
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {/* Image properties go here */}
                {/* <TextField
                                   fullWidth
                                   multiline
                                   rows={10}
                                   id="outlined-multiline-flexible"
                                   label="Html"
                                  value={
                                    kioskState?.templatemeta?.templatedata?.fullpage?.html
                                  }
                                  onChange={(e) =>
                                    setKioskState({
                                      ...kioskState,
                                      templatemeta: {
                                        templatedata: {
                                          ...kioskState?.templatemeta?.templatedata,
                                          fullpage: {
                                            assets:[],//kioskState?.templatemeta?.templatedata.fullpage?.assets||[],
                                            //...kioskState?.templatemeta?.templatedata.fullpage,
                                            html:e?.target?.value||'',//kioskState?.templatemeta?.templatedata.fullpage?.html,
                                            //source: e?.target?.value,
                                          },
                                        },
                                      },
                                    })
                                  }
                                /> */}
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* </Accordion> */}
      <Grid container justifyContent="center">
        <Grid>
          <Button
            variant="outlined"
            style={{ margin: "2em" }}
            color="primary"
            onClick={submitForm}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImagebackgroundUIconfig;
