import { IKiosk } from "../../globalprops";
import DynamicAdvancedTemplateCssFields from "./dynamicAdvancedTemplateCssFields";
import DynamicAdvancedTemplateJSFields from "./dynamicAdvancedTemplateJSFields";
import DynamicButtonFields from "./dynamicButtonFields";
import DynamicHeaderFields from "./dynamicHeaderFields";

type fields = {
  type: "header" | "buttons" | "advanced-template-css" | "advanced-template-js";
  removeField: any;
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
};
const DynamicFields = ({
  type,
  removeField,
  kioskState,
  setKioskState,
}: fields) => {
  if (type === "header") {
    return (
      <DynamicHeaderFields
        removeField={removeField}
        kioskState={kioskState}
        setKioskState={setKioskState}
      />
    );
  } else if (type === "buttons") {
    return (
      <DynamicButtonFields
        removeField={removeField}
        kioskState={kioskState}
        setKioskState={setKioskState}
      />
    );
  } else if (type == "advanced-template-css") {
    return (
      <DynamicAdvancedTemplateCssFields
        removeField={removeField}
        kioskState={kioskState}
        setKioskState={setKioskState}
      />
    );
  } else if (type == "advanced-template-js") {
    return (
      <DynamicAdvancedTemplateJSFields
        removeField={removeField}
        kioskState={kioskState}
        setKioskState={setKioskState}
      />
    );
  } else {
    return <></>;
  }
};

export default DynamicFields;
