import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Button,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { styled } from "@mui/material/styles";
import { isUserSignedIn } from "../../hooks/useAuth";
import Login from "../login";
import Navbar from "../../components/navbar";
import { TextField, FormControl } from "@mui/material";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import BasicSelect from "../../components/dropdown";
import { addkiosk, getkioskNames, getkiosktemplates } from "../../apis";
import { IKiosk } from "../../globalprops";
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.h3,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: "center",
}));
const AddNewKiosk = () => {
  const [kioskName, setKioskName] = useState("");
  const [kioskWidth, setKioskWidth] = useState("");
  const [kioskHeight, setKioskHeight] = useState("");
  const [kiosks, setKioskSinDropdown] = useState<any>([]);
  const [selectedKiosk, setSelectedKiosk] = useState("");

  const [IskioskConfigCopychecked, setIskioskConfigCopychecked] =
    useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIskioskConfigCopychecked(event.target.checked);
  };

  const [selectedTemplate, setSelectedTemplate] = useState({
    key: "",
    value: "",
  });
  const [templates, setTemplates] = useState([{ key: "", value: "" }]);
  const templateSelectHandler = (e: any) => {
    setSelectedTemplate({ key: e?.target?.value, value: e?.target?.value });
  };

  const kioskSelectHandler = (event: any) => {
    setSelectedKiosk(event.target.value as string);
  };

  const templatemeta = {
    templatedata: {
      cssProps: {
        theme: {},
        headerslider: {},
        headervideo: {},
        slicer: {},
        buttongrp: {},
        slider: {},
      },
    },
  };
  const submitForm = async () => {
    const kiosk: IKiosk = {
      kioskname: kioskName,
      templatename: selectedTemplate?.value,
      templatemeta: templatemeta,
      kioskwidth: kioskWidth,
      kioskheight: kioskHeight,
      created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
      modified_at: new Date().toISOString().slice(0, 19).replace("T", " "),
      isactive: true,
    };

    if (
      !IskioskConfigCopychecked &&
      (kioskName === undefined ||
        kioskName === "" ||
        selectedTemplate?.value === undefined ||
        selectedTemplate?.value === "" ||
        kioskWidth === undefined ||
        kioskWidth === "" ||
        kioskHeight === undefined ||
        kioskHeight === "")
    ) {
      StatusAlertService.showError(
        "All fields are mandotory. Please make sure you input each field",
      );
      return;
    } else if (
      IskioskConfigCopychecked &&
      (kioskName === undefined ||
        kioskName === "" ||
        selectedKiosk === undefined ||
        selectedKiosk === "")
    ) {
      StatusAlertService.showError(
        "All fields are mandotory. Please make sure you input each field",
      );
      return;
    }

    let isKioskAdded = false;
    isKioskAdded = await addkiosk(
      kiosk,
      IskioskConfigCopychecked,
      selectedKiosk,
    );
    {
      isKioskAdded
        ? StatusAlertService.showSuccess(
            "A new kiosk with name " + kioskName + " is added!",
          )
        : StatusAlertService.showError(
            "A kiosk with name " +
              kioskName +
              " Exist in database. Please name it with something else!",
          );
    }
  };

  useEffect(() => {
    const load = async () => {
      const templateResults = await getkiosktemplates();
      const kioskNames = await getkioskNames();
      setKioskSinDropdown(kioskNames);
      setTemplates(templateResults);
    };
    load();
  }, []);

  return (
    <>
      {isUserSignedIn() ? (
        <>
          <Navbar />
          <Div>Add New Kiosk</Div>
          <Box sx={{ m: 1 }} />
          <StatusAlert />
          <Container maxWidth="lg">
            <FormControlLabel
              control={
                <Checkbox
                  checked={IskioskConfigCopychecked}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  icon={<HighlightOffRoundedIcon />}
                />
              }
              label={
                IskioskConfigCopychecked
                  ? "Disable to manually add new kiosk template"
                  : "Enable the checkbox to Copy Kiosk configuration"
              }
            />
            <Box sx={{ m: 6 }} />
            {IskioskConfigCopychecked ? (
              <BasicSelect
                handleChange={kioskSelectHandler}
                items={kiosks}
                label="Select Kiosk Configuration to copy over"
                selectedValue={selectedKiosk}
                // key={""}
              />
            ) : (
              ""
            )}

            <Box sx={{ m: 2 }} />

            <FormControl fullWidth>
              <TextField
                label="Kiosk Name"
                color="primary"
                focused
                onChange={(e) => setKioskName(e?.target?.value)}
              />
            </FormControl>
            <Box sx={{ m: 2 }} />
            {!IskioskConfigCopychecked ? (
              <>
                <BasicSelect
                  handleChange={templateSelectHandler}
                  items={templates}
                  label="Select a Template"
                  selectedValue={selectedTemplate.value}
                  key={""}
                />
                <Box
                  sx={{ m: 2 }}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControl style={{ padding: "1em" }}>
                    <TextField
                      label="Kiosk Width"
                      color="primary"
                      focused
                      onChange={(e) => setKioskWidth(e?.target?.value)}
                    />
                  </FormControl>
                  <FormControl style={{ padding: "1em" }}>
                    <TextField
                      label="Kiosk Height"
                      color="primary"
                      focused
                      onChange={(e) => setKioskHeight(e?.target?.value)}
                    />
                  </FormControl>
                </Box>
              </>
            ) : (
              ""
            )}
            <Div>
              <Button
                variant="outlined"
                style={{ margin: "2em" }}
                color="primary"
                onClick={submitForm}
              >
                Submit
              </Button>
            </Div>
            <Div>
              <Alert severity="info">
                <h1>Kiosk Naming Convention!</h1>
                <br />
                <hr />
                <br />
                <ul>
                  <h3 style={{ textAlign: "left" }}>
                    1. Name KIOSK in a{" "}
                    <code>
                      lowercase letters without any special
                      charecter/number/space
                    </code>
                  </h3>
                  <Alert severity="success">
                    IE: testkiosk, marylandautoshowkiosk, marylandstatefair,
                    pivnettestkiosk
                  </Alert>
                  <br />
                  <hr />
                  <h3 style={{ textAlign: "left" }}>
                    2. Name KIOSK Dependencies in this format:{" "}
                    <code>KioskName-dependency-NameOfDependency</code>
                  </h3>
                  <Alert severity="success">
                    IE: TestKiosk-dependency-HeaderVideo,
                    msf-dependency-kioskvideo
                  </Alert>
                </ul>
              </Alert>
            </Div>
          </Container>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AddNewKiosk;
