import { Grid, TextField } from "@mui/material";
import { IKiosk } from "../../globalprops";
import CustomCheckbox from "../../components/checkbox";
import { JsonParser } from "../../globalhelper";
type TData = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
};
const BannerConfig = ({ kioskState, setKioskState }: TData) => {
  return (
    <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <>
        {/* Header Text*/}
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Header Text"
            multiline={true}
            size="medium"
            maxRows={50000}
            value={kioskState?.templatemeta?.templatedata?.banner?.text?.text}
            onChange={(e) =>
              setKioskState({
                ...kioskState,
                templatemeta: {
                  templatedata: {
                    ...kioskState?.templatemeta?.templatedata,
                    banner: {
                      ...kioskState?.templatemeta?.templatedata.banner,
                      text: {
                        ...kioskState?.templatemeta?.templatedata?.banner?.text,
                        text: e?.target?.value,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <CustomCheckbox
            kioskState={kioskState}
            setKioskState={setKioskState}
            chprops={{ label: "Float", type: "bannertext-isfloat" }}
          />
        </Grid>
        <Grid item xs={1}>
          <CustomCheckbox
            kioskState={kioskState}
            setKioskState={setKioskState}
            chprops={{
              label: "Visibility",
              type: "bannertext-isvisible",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="CSS Properties"
            multiline={true}
            size="medium"
            maxRows={50000}
            value={JSON.stringify(
              kioskState?.templatemeta?.templatedata?.banner?.text?.css,
            )}
            onChange={(e) =>
              setKioskState({
                ...kioskState,
                templatemeta: {
                  templatedata: {
                    ...kioskState?.templatemeta?.templatedata,
                    banner: {
                      ...kioskState?.templatemeta?.templatedata.banner,
                      text: {
                        ...kioskState?.templatemeta?.templatedata?.banner?.text,
                        css: JsonParser(e?.target?.value),
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid>
      </>
      <>
        {/* Header Image*/}
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Image Source"
            multiline={true}
            size="medium"
            maxRows={50000}
            value={kioskState?.templatemeta?.templatedata?.banner?.image?.src}
            onChange={(e) =>
              setKioskState({
                ...kioskState,
                templatemeta: {
                  templatedata: {
                    ...kioskState?.templatemeta?.templatedata,
                    banner: {
                      ...kioskState?.templatemeta?.templatedata.banner,
                      image: {
                        ...kioskState?.templatemeta?.templatedata?.banner
                          ?.image,
                        src: e?.target?.value,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <CustomCheckbox
            kioskState={kioskState}
            setKioskState={setKioskState}
            chprops={{ label: "Visible", type: "bannerimage-isvisible" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="CSS Properties"
            multiline={true}
            size="medium"
            maxRows={50000}
            value={JSON.stringify(
              kioskState?.templatemeta?.templatedata?.banner?.image?.css,
            )}
            onChange={(e) =>
              setKioskState({
                ...kioskState,
                templatemeta: {
                  templatedata: {
                    ...kioskState?.templatemeta?.templatedata,
                    banner: {
                      ...kioskState?.templatemeta?.templatedata.banner,
                      image: {
                        ...kioskState?.templatemeta?.templatedata?.banner
                          ?.image,
                        css: JsonParser(e?.target?.value),
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid>
      </>
      <>
        {/* Header Video*/}
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Video Source"
            multiline={true}
            size="medium"
            maxRows={50000}
            value={kioskState?.templatemeta?.templatedata?.banner?.video?.src}
            onChange={(e) =>
              setKioskState({
                ...kioskState,
                templatemeta: {
                  templatedata: {
                    ...kioskState?.templatemeta?.templatedata,
                    banner: {
                      ...kioskState?.templatemeta?.templatedata.banner,
                      video: {
                        ...kioskState?.templatemeta?.templatedata?.banner
                          ?.video,
                        src: e?.target?.value,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <CustomCheckbox
            kioskState={kioskState}
            setKioskState={setKioskState}
            chprops={{ label: "Visible", type: "bannervideo-isvisible" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="CSS Properties"
            multiline={true}
            size="medium"
            maxRows={50000}
            value={JSON.stringify(
              kioskState?.templatemeta?.templatedata?.banner?.video?.css,
            )}
            onChange={(e) =>
              setKioskState({
                ...kioskState,
                templatemeta: {
                  templatedata: {
                    ...kioskState?.templatemeta?.templatedata,
                    banner: {
                      ...kioskState?.templatemeta?.templatedata.banner,
                      video: {
                        ...kioskState?.templatemeta?.templatedata?.banner
                          ?.video,
                        css: JsonParser(e?.target?.value),
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid>
      </>
    </Grid>
  );
};

export default BannerConfig;
