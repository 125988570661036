import { TFormState } from "../../../globalprops";
import { styled } from "styled-components";
const BannerTextWrapper = styled.div`
  div.banner {
    position: relative;
    font-family: Arial;
    font-size: 25px;
    font-weight: bold;
    -webkit-animation: movingtitle 10s infinite linear;
    animation: movingtitle 10s infinite linear;
    white-space: nowrap;
    overflow: hidden;
  }

  /* @keyframes movingtitle{
    from{
      transform:translate(-100%);
    }
    to{
      transform:translate(100%);
    }
  } */

  @-webkit-keyframes movingtitle {
    from {
      text-indent: 100%;
    }
    to {
      text-indent: -100%;
    }
  }
  @keyframes movingtitle {
    from {
      text-indent: 100%;
    }
    to {
      text-indent: -100%;
    }
  }
`;

const Banner = ({ templatedata }: TFormState) => {
  //   if(templatedata?.horizontalTemplate?.bannerLeft?.image?.isVisible){
  //       return (
  //         <img
  //           src={templatedata?.horizontalTemplate?.bannerLeft?.image?.src}
  //           alt="banner-image"
  //           style={templatedata?.horizontalTemplate?.bannerLeft?.image?.css}
  //         />
  //       );
  //   }else if(templatedata?.horizontalTemplate?.bannerLeft?.video?.isVisible){
  //     return (
  //       <iframe
  //         src={templatedata?.horizontalTemplate?.bannerLeft?.video.src}
  //         frameBorder="0"
  //         style={templatedata?.horizontalTemplate?.bannerLeft?.video?.css}
  //       ></iframe>
  //     );
  // }

  if (templatedata?.banner?.text?.isVisible) {
    return (
      <BannerTextWrapper>
        <div
          className={`banner${
            templatedata?.banner.text?.isFloating ? "" : "-disabled"
          }`}
          style={templatedata?.banner?.text?.css}
        >
          <p>{templatedata?.banner?.text?.text}</p>
        </div>
      </BannerTextWrapper>
    );
  } else if (templatedata?.banner?.image?.isVisible) {
    return (
      <img
        src={templatedata?.banner?.image?.src}
        alt="banner-image"
        style={templatedata?.banner?.image.css}
      />
    );
  } else if (templatedata?.banner?.video?.isVisible) {
    return (
      // <div style={templatedata?.banner?.video.css}>
      <iframe
        src={templatedata?.banner?.video?.src}
        frameBorder="0"
        style={templatedata?.banner?.video.css}
      ></iframe>
      // </div>
    );
  }

  return <></>;
};

export default Banner;
