import { Box, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import { isUserSignedIn } from "../../hooks/useAuth";
import Login from "../login";
import Navbar from "../../components/navbar";
import StandardImageList from "../../components/imagelist";
import BasicSelect from "../../components/dropdown";
import StandardDropzone from "../../components/dropzone";
import { getkioskNames, uploadToS3, getassetsByKiosk } from "../../apis";
const { REACT_APP_S3_Verify_Upload_URL, REACT_APP_TemplateParts } = process.env;
type TImage = {
  id: string;
  kioskname: string;
  imagename: string;
  alt: string;
  meta: string;
  created_at: string;
  modified_at: string;
  isactive: number;
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.h3,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: "center",
}));

const AssetManager = () => {
  const [kiosks, setKioskSinDropdown] = useState<any>([]);
  const [themeParts, setThemepart] = useState<any>([]);

  const [selectedKiosk, setSelectedKiosk] = useState("");
  const [selectKioskToUpload, setSelectKioskToUpload] = useState("");
  const [selectedThemepart, setselectedThemepart] = useState("");
  const [uploadstatus, setUploadstatus] = useState("");
  const [images, setImages] = useState<TImage[]>([
    {
      id: "null",
      kioskname: "null",
      alt: "null",
      created_at: "null",
      imagename: "null",
      isactive: 0,
      meta: "null",
      modified_at: "null",
    },
  ]);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    const asyncFunc = async () => {
      const imagearr = await getassetsByKiosk(selectedKiosk);
      setImages(imagearr);
    };
    asyncFunc();
  }, [selectedKiosk, isUpdate]);

  useEffect(() => {
    const asyncFunc = async () => {
      const kioskNames = await getkioskNames();
      setKioskSinDropdown(kioskNames);
      setThemepart(JSON.parse(REACT_APP_TemplateParts || ""));
    };
    asyncFunc();
  }, []);

  const showKioskImagesHandler = (event: any) => {
    setSelectedKiosk(event.target.value as string);
  };

  const selectKioskToUploadHandler = (event: any) => {
    setSelectKioskToUpload(event.target.value as string);
  };

  const selectedThemepartHandler = (event: any) => {
    setselectedThemepart(event.target.value as string);
  };

  const getUploadParams = (params: any) => {
    try {
      setUploadstatus("get-upload-params" + "-" + Date.now());
      const body = new FormData();
      body.append("fileField", params.file);
      //const filesizeInMb=params.file.size/1000000;
      // if(filesizeInMb>2){
      //   console.log("Upload size can not exceed 2MB size---->",filesizeInMb);
      //   StatusAlertService.showError(`Upload size should not exceed 2MB! current file size is ${filesizeInMb} MB`);
      //   //return { url: REACT_APP_S3_Verify_Upload_URL, body };;
      // }

      return { url: REACT_APP_S3_Verify_Upload_URL, body };
      //return { url: "https://api.pivnetkiosk.com/uploadToS3",body};
      //const data={};
      //return {url:"https://api.pivnetkiosk.com/uploadToS3",params}
    } catch (e) {
      setUploadstatus("get-upload-params" + "-" + Date.now());
    }
  };

  // called every time a file's `status` changes

  const handleChangeStatus = ({ meta, file }: any, status: any) => {
    try {
      console.log(status, meta, file);
      setUploadstatus(status + "-" + Date.now());
    } catch (e) {
      setUploadstatus(status + "-" + Date.now());
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files: any, allFiles: any) => {
    const meta = {
      kioskname: selectKioskToUpload,
      templatepart: selectedThemepart,
    };
    try {
      if (
        selectKioskToUpload === undefined ||
        selectKioskToUpload === "" ||
        selectedThemepart === undefined ||
        selectedThemepart === ""
      ) {
        StatusAlertService.showError("Kiosk and Theme part must be Selected!");
        return;
      }

      //handle upload submit to server here
      await uploadToS3(files, allFiles, meta);
      console.log(files.map((f: any) => f.meta));
      allFiles.forEach((f: any) => f.remove());
      setUploadstatus("submitted" + "-" + Date.now());
      setIsUpdate((prev) => !prev);
      //update imagelist to show newly uploaded image(s)
      setSelectedKiosk("");
      setTimeout(() => {
        setSelectedKiosk(selectKioskToUpload);
      }, 900);
    } catch (e) {
      setUploadstatus("submit-error" + "-" + Date.now());
    }
  };

  return (
    <>
      {isUserSignedIn() ? (
        <>
          <Navbar />
          <Box sx={{ m: 5 }} />
          <StatusAlert />
          <Div>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Grid item xl={6}>
                  <BasicSelect
                    label="Select Kiosk To Upload"
                    handleChange={selectKioskToUploadHandler}
                    items={kiosks}
                    selectedValue={selectKioskToUpload}
                  />
                </Grid>
                <Box sx={{ m: 5 }} />
                <Grid item xl={6}>
                  <BasicSelect
                    label="Select Part of Theme"
                    handleChange={selectedThemepartHandler}
                    items={themeParts}
                    selectedValue={selectedThemepart}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <StandardDropzone
                  getUploadParams={getUploadParams}
                  handleChangeStatus={handleChangeStatus}
                  handleSubmit={handleSubmit}
                  uploadstatus={uploadstatus}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12} style={{ padding: "10px" }}>
                  <Item>
                    <BasicSelect
                      label="Select Kiosk"
                      handleChange={showKioskImagesHandler}
                      items={kiosks}
                      selectedValue={selectedKiosk}
                    />
                  </Item>
                </Grid>
                {selectedKiosk ? (
                  <Grid item xs={12}>
                    <Item>
                      <StandardImageList
                        items={images}
                        isControlOn={true}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                      />
                    </Item>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Item>Select Kiosk to list its assets</Item>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default AssetManager;
