import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/nopage";
import Admin from "./pages/admin";
import Kiosk from "./pages/kiosk";
import Home from "./pages/home";
import AddNewKiosk from "./pages/addnewkiosk";
import UpdateKiosk from "./pages/updatekiosk";
import AssetManager from "./pages/assetmanager";
import "./App.css";
import "react-status-alert/dist/status-alert.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="admin" element={<Admin />} />
        <Route path="home" element={<Admin />} />
        <Route path="index" element={<Admin />} />
        <Route path="addkiosk" element={<AddNewKiosk />} />
        <Route path="updatekiosk/:id" element={<UpdateKiosk />} />
        <Route path="kiosk/:id" element={<Kiosk />} />
        <Route path="assets" element={<AssetManager />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
