import Login from "../login";
import { isUserSignedIn } from "../../hooks/useAuth";
import styled from "styled-components";

const HomeWrapper = styled.div``;
const Home = () => {
  if (isUserSignedIn() && location.pathname !== "/admin") {
    location.pathname = "admin";
  } else if (!isUserSignedIn() && location.pathname !== "/") {
    location.pathname = "/";
  }
  return <HomeWrapper>{!isUserSignedIn() ? <Login /> : <div />}</HomeWrapper>;
};

export default Home;
