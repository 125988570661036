import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TemplateMaker from "../../components/themplateMaker";
import styled from "styled-components";
import { IKiosk } from "../../globalprops";
import { getkioskByName } from "../../apis";
const KioskWrapper = styled.div`
  height: 100vh;
`;
const Kiosk = () => {
  const location = useLocation();
  const [kioskState, setKioskState] = useState<IKiosk>({
    id: 0,
    kioskname: "",
    templatemeta: {
      templatedata: {
        cssProps: {
          headerslider: {},
          headervideo: {},
          buttongrp: {},
          slicer: {},
          theme: {},
        },
      },
    },
    templatename: "",
    kioskwidth: "",
    kioskheight: "",
    created_at: "",
    modified_at: "",
    isactive: false,
  });
  //pull kiosk properties by kiosk name from database and feed TemplateMaker component
  useEffect(() => {
    //call database with kiosk name to get the kiosk properties dynamically construct the view for kiosk
    const asyncFunc = async () => {
      const kioskName = location.pathname.split("/")[2];
      const kiosk = await getkioskByName(kioskName);
      console.log(kiosk);
      setKioskState(kiosk);
    };
    asyncFunc();
  }, []);

  return (
    <KioskWrapper>
      <TemplateMaker templatedata={kioskState?.templatemeta?.templatedata} />
    </KioskWrapper>
  );
};

export default Kiosk;
