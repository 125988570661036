const {
  REACT_APP_aws_cognito_region,
  REACT_APP_aws_user_pools_web_client_id,
  React_APP_aws_user_pools_id,
} = process.env;
export default {
  aws_user_pools_id: React_APP_aws_user_pools_id,
  aws_user_pools_web_client_id: REACT_APP_aws_user_pools_web_client_id,
  REACT_APP_aws_cognito_region: REACT_APP_aws_cognito_region,
};
