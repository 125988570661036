import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import { IKiosk } from "../../globalprops";
import DynamicFields from "../../components/dynamicFields";
type TData = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
  HeaderTypeHandler: any;
  removeHeaderField: any;
  AddNewHeaderField: any;
};
const HeaderConfig = ({
  kioskState,
  setKioskState,
  HeaderTypeHandler,
  removeHeaderField,
  AddNewHeaderField,
}: TData) => {
  return (
    <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Header Type</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="isvideo"
              control={<Radio />}
              label="Video"
              checked={kioskState?.templatemeta?.templatedata?.header?.isvideo}
              onChange={HeaderTypeHandler}
            />
            <FormControlLabel
              value="isSlider"
              control={<Radio />}
              checked={kioskState?.templatemeta?.templatedata?.header?.isSlider}
              label="Slider"
              onChange={HeaderTypeHandler}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <DynamicFields
        type="header"
        removeField={removeHeaderField}
        kioskState={kioskState}
        setKioskState={setKioskState}
      />
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        {!kioskState?.templatemeta?.templatedata?.header?.isvideo ? (
          <Button variant="outlined" onClick={AddNewHeaderField}>
            Add New
          </Button>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderConfig;
