import * as React from "react";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { IKiosk } from "../../globalprops";

type TCheckbox = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
  chprops: {
    label: string;
    type:
      | "bannertext-isfloat"
      | "bannertext-isvisible"
      | "divider-isshow"
      | "bannervideo-isvisible"
      | "bannerimage-isvisible"
      | "right-bannerimage-isvisible"
      | "left-bannerimage-isvisible"
      | "left-bannervideo-isvisible"
      | "right-bannervideo-isvisible";
  };
};
const CustomCheckbox = ({ kioskState, setKioskState, chprops }: TCheckbox) => {
  switch (chprops.type) {
    case "divider-isshow":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState.templatemeta?.templatedata?.slicer?.status || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        slicer: {
                          ...kioskState.templatemeta.templatedata.slicer,
                          status:
                            !kioskState.templatemeta.templatedata.slicer
                              ?.status,
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );

    case "bannertext-isfloat":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState.templatemeta?.templatedata?.banner?.text
                    ?.isFloating || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        banner: {
                          ...kioskState.templatemeta.templatedata.banner,
                          text: {
                            ...kioskState.templatemeta.templatedata?.banner
                              ?.text,
                            isFloating:
                              !kioskState?.templatemeta?.templatedata?.banner
                                ?.text?.isFloating,
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );
    case "bannertext-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.banner?.text
                    ?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        banner: {
                          ...kioskState.templatemeta.templatedata.banner,
                          text: {
                            ...kioskState.templatemeta.templatedata.banner
                              ?.text,
                            isVisible:
                              !kioskState?.templatemeta?.templatedata?.banner
                                ?.text?.isVisible,
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );
    case "bannervideo-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.banner?.video
                    ?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        banner: {
                          ...kioskState.templatemeta.templatedata.banner,
                          video: {
                            ...kioskState.templatemeta.templatedata.banner
                              ?.video,
                            isVisible:
                              !kioskState?.templatemeta?.templatedata?.banner
                                ?.video?.isVisible,
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );

    case "bannerimage-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.banner?.image
                    ?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        banner: {
                          ...kioskState.templatemeta.templatedata.banner,
                          image: {
                            ...kioskState.templatemeta.templatedata.banner
                              ?.image,
                            isVisible:
                              !kioskState?.templatemeta?.templatedata?.banner
                                ?.image?.isVisible,
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );

    case "right-bannerimage-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.horizontalTemplate
                    ?.bannerRight?.image?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        horizontalTemplate: {
                          ...kioskState.templatemeta.templatedata
                            ?.horizontalTemplate,
                          bannerRight: {
                            ...kioskState.templatemeta.templatedata
                              ?.horizontalTemplate?.bannerRight,
                            image: {
                              ...kioskState.templatemeta.templatedata
                                ?.horizontalTemplate?.bannerRight?.image,
                              isVisible:
                                !kioskState?.templatemeta?.templatedata
                                  ?.horizontalTemplate?.bannerRight?.image
                                  ?.isVisible,
                            },
                          },
                        },
                        // banner: {
                        //   ...kioskState.templatemeta.templatedata.banner,
                        //   image: {
                        //     ...kioskState.templatemeta.templatedata.banner
                        //       ?.image,
                        //     isVisible:
                        //       !kioskState?.templatemeta?.templatedata?.banner
                        //         ?.image?.isVisible,
                        //   },
                        // },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );
    case "left-bannerimage-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.horizontalTemplate
                    ?.bannerLeft?.image?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        horizontalTemplate: {
                          ...kioskState.templatemeta.templatedata
                            ?.horizontalTemplate,
                          bannerLeft: {
                            ...kioskState.templatemeta.templatedata
                              ?.horizontalTemplate?.bannerLeft,
                            image: {
                              ...kioskState.templatemeta.templatedata
                                ?.horizontalTemplate?.bannerLeft?.image,
                              isVisible:
                                !kioskState?.templatemeta?.templatedata
                                  ?.horizontalTemplate?.bannerLeft?.image
                                  ?.isVisible,
                            },
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );

    case "left-bannervideo-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.horizontalTemplate
                    ?.bannerLeft?.video?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        horizontalTemplate: {
                          ...kioskState.templatemeta.templatedata
                            ?.horizontalTemplate,
                          bannerLeft: {
                            ...kioskState.templatemeta.templatedata
                              ?.horizontalTemplate?.bannerLeft,
                            video: {
                              ...kioskState.templatemeta.templatedata
                                ?.horizontalTemplate?.bannerLeft?.video,
                              isVisible:
                                !kioskState?.templatemeta?.templatedata
                                  ?.horizontalTemplate?.bannerLeft?.video
                                  ?.isVisible,
                            },
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );
    case "right-bannervideo-isvisible":
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  kioskState?.templatemeta?.templatedata?.horizontalTemplate
                    ?.bannerRight?.video?.isVisible || false
                }
                onChange={() =>
                  setKioskState({
                    ...kioskState,
                    templatemeta: {
                      templatedata: {
                        ...kioskState.templatemeta.templatedata,
                        horizontalTemplate: {
                          ...kioskState.templatemeta.templatedata
                            ?.horizontalTemplate,
                          bannerRight: {
                            ...kioskState.templatemeta.templatedata
                              ?.horizontalTemplate?.bannerRight,
                            video: {
                              ...kioskState.templatemeta.templatedata
                                ?.horizontalTemplate?.bannerRight?.video,
                              isVisible:
                                !kioskState?.templatemeta?.templatedata
                                  ?.horizontalTemplate?.bannerRight?.video
                                  ?.isVisible,
                            },
                          },
                        },
                      },
                    },
                  })
                }
              />
            }
            label={chprops.label}
          />
        </FormGroup>
      );

    default:
      return <></>;
  }
};

export default CustomCheckbox;
