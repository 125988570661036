import { IKiosk, TFormState } from "./globalprops";

export const JsonParser = (data: any) => {
  let json: Record<string, any> = {};
  try {
    json = JSON.parse(data);
    //return json;
  } catch (e) {
    console.log(`This is not a valid json: `, data, e);
    alert(e);
    //return data;
    //json={};
  }
  return json;
};

export const FormStateJsonParser = (data: any) => {
  let json: TFormState = {
    templatedata: {
      cssProps: {
        theme: {},
        headerslider: {},
        headervideo: {},
        slicer: {},
        buttongrp: {},
      },
    },
  };
  try {
    json = JSON.parse(data);
    console.log(`This is a valid json: `, json);
  } catch (e) {
    console.log(`This is not a valid json<TFormState>: `, data, e);
  }
  return json;
};

export const IsValidKioskState = (data: IKiosk) => {
  let json: IKiosk = {
    id: 0,
    kioskname: "",
    templatename: "",
    kioskheight: "",
    kioskwidth: "",
    modified_at: "",
    created_at: "",
    isactive: false,
    templatemeta: {
      templatedata: {
        cssProps: {
          theme: {},
          headerslider: {},
          headervideo: {},
          slicer: {},
          buttongrp: {},
        },
      },
    },
  };
  try {
    json = JSON.parse(JSON.stringify(data));
    console.log(`This is a valid json: `, json);
    return true;
  } catch (e) {
    console.log(`This is not a valid json<IKiosk>: `, data, e);
  }
  return false;
};

export const KioskStateJsonParser = (data: any) => {
  let json: IKiosk = {
    id: 0,
    kioskname: "",
    templatemeta: {
      templatedata: {
        cssProps: {
          theme: {},
          headerslider: {},
          headervideo: {},
          slicer: {},
          buttongrp: {},
        },
      },
    }, //"",//string | undefined | null; //TFormState,//|
    templatename: "",
    kioskwidth: "",
    kioskheight: "",
    created_at: "",
    modified_at: "",
    isactive: false,
  };
  try {
    //let t=JSON.parse(JSON.parse(data)?.templatedata)
    json = JSON.parse(JSON.stringify(data));
    console.log(`This is a valid json: `, json);
  } catch (e) {
    console.log(`This is not a valid json<TFormState>: `, data, e);
  }
  return json;
};
