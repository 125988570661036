import { Grid, Button, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactElement, Fragment } from "react";
import { IKiosk } from "../../globalprops";
import { JsonParser } from "../../globalhelper";

type field = {
  source: string;
  imagesrc: string;
  linkto: string;
  order: number;
  key: number;
};
type fields = {
  removeField: any;
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
};

const DynamicButtonFields = ({
  kioskState,
  removeField,
}: fields): ReactElement => {
  let fieldsarray: any = [];
  fieldsarray = kioskState?.templatemeta?.templatedata.buttons;

  const onChangeHandler = (
    e: any,
    index: any,
    field: field,
    inputType: string,
  ) => {
    fieldsarray.map((field: any, i: number) => {
      if (i === index) {
        switch (inputType) {
          case "source":
            field.source = e?.target?.value;
            field.imagesrc = e?.target?.value;
            break;
          case "linkto":
            field.linkto = e?.target?.value;
            break;
          case "order":
            field.order = e?.target?.value;
            break;
          case "css":
            field.css = JsonParser(e?.target?.value); //JSON.parse(e?.target?.value);// (function(){try{console.log("t");return JSON.parse(e?.target?.value)}catch(error){console.log(error);}})
            break;
          default:
            break;
        }
      }
    });
  };

  return (
    <>
      {fieldsarray?.map((field: any, index: number) => {
        return (
          <Fragment key={field.key}>
            <Grid item xs={3}>
              <TextField
                id="outlined-multiline-flexible"
                label="Source"
                multiline={false}
                defaultValue={field?.source}
                size="medium"
                fullWidth
                maxRows={5}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, field, "source")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-multiline-flexible"
                label="Linkto"
                multiline={false}
                defaultValue={field?.linkto}
                fullWidth
                maxRows={5}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, field, "linkto")}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                id="outlined-multiline-flexible"
                label="Order"
                multiline={false}
                type="number"
                defaultValue={field?.order}
                fullWidth
                maxRows={5}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, field, "order")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="css"
                multiline={true}
                type="text"
                defaultValue={JSON.stringify(field?.css)}
                fullWidth
                maxRows={50}
                minRows={3}
                onChange={(e) => onChangeHandler(e, index, field, "css")}
              />
            </Grid>
            <Grid item xs={1}>
              {index !== 0 ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeField(index)}
                >
                  <DeleteIcon style={{ fontSize: "3em" }} />
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Fragment>
        );
      })}
    </>
  );
};

export default DynamicButtonFields;
