import { TFormState } from "../../../globalprops";
import Banner from "./banner.component";
import SliderHeaderComponent from "./sliderheader.component";
import VideoHeaderComponent from "./videoheader.component";

const GenericTemplate = ({ templatedata }: TFormState) => {
  if (templatedata?.header?.isvideo) {
    return (
      <>
        <Banner templatedata={templatedata} />
        <VideoHeaderComponent templatedata={templatedata} />
      </>
    );
  } else {
    return (
      <>
        <Banner templatedata={templatedata} />
        <SliderHeaderComponent templatedata={templatedata} />
      </>
    );
  }
};

export default GenericTemplate;
