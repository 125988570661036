import { Button, Link } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import FormDialog from "../dialog";
import { deletekiosk, getkiosks, getactivekiosks } from "../../apis";
import { IKiosk } from "../../globalprops";
import SkeletonSelector from "../skeletons";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import BasicModal from "../modal";
const { REACT_APP_passwordTodeleteKiosk } = process.env;

const DataTable = () => {
  const [state, setState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [password, setPassword] = useState("");
  const [kioskId, setkioskId] = useState(-1);
  const [rows, setRows] = useState<IKiosk[] | any>();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const [cellValues, setCellValues] = useState("");

  useEffect(() => {
    const loadKiosks = async () => {
      const kiosklists = await getactivekiosks();
      setRows(kiosklists);
    };
    loadKiosks();
  }, [refresh]);

  const handleClose = () => {
    setState(false);
  };

  const handleRemove = async () => {
    console.log("removing logic will be handled here");
    if (password === REACT_APP_passwordTodeleteKiosk) {
      await deletekiosk(kioskId);
      setState(false);
      setRefresh((prev) => !prev);
      StatusAlertService.showSuccess(
        "Kiosk with an id=" + kioskId + " is deleted",
      );
    } else {
      StatusAlertService.showError(
        "The password is incorrect. The Kiosk with an id=" +
          kioskId +
          " is not deleted",
      );
    }
  };

  const ModalRemoveClick = (e: any, cellValues: any) => {
    setState(true);
    setkioskId(cellValues?.id);
    console.log(cellValues, e);
  };
  const UpdateClick = (e: any, cellValues: any) => {
    const kioskname = cellValues?.row?.kioskname;
    console.log(cellValues, e);
    window.location.href = `/updatekiosk/${kioskname}?template=${cellValues?.row?.templatename}`;
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", type: "string", width: 100 },
    {
      field: "kioskname",
      headerName: "Kiosk Name",
      type: "string",
      width: 200,
    },
    {
      field: "templatename",
      headerName: "Template Name",
      type: "string",
      width: 200,
    },
    {
      field: "resolution",
      headerName: "Resolution(WxH)",
      type: "string",
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.kioskwidth + " X " + params.row.kioskheight || ""}`,
    },
    {
      field: "created_at",
      headerName: "Date Added",
      type: "string",
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.created_at?.split("T")[0] || ""}`,
    },
    {
      field: "linktokiosk",
      headerName: "Go",
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      width: 90,
      renderCell: (cellValues: any) => {
        // `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        return (
          <Link
            underline="hover"
            href={"/kiosk/" + cellValues?.row?.kioskname}
            target="__blank"
          >
            <LaunchIcon />
          </Link>
        );
      },
    },
    {
      field: "preview",
      headerName: "Preview",
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      width: 90,
      renderCell: (cellValues: any) => {
        // `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        return (
          <Button
            variant="contained"
            color="info"
            onClick={(event: any) => {
              setCellValues(cellValues);
              console.log(event);
              handleModalOpen();
            }}
          >
            <RemoveRedEyeIcon />
          </Button>
        );
      },
    },
    {
      field: "update",
      headerName: "Update",
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      width: 90,
      renderCell: (cellValues: any) => {
        // `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(event: any) => {
              UpdateClick(event, cellValues);
            }}
          >
            <UpdateIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      width: 90,
      renderCell: (cellValues: any) => {
        // `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        return (
          <Button
            variant="contained"
            color="error"
            onClick={(event: any) => {
              ModalRemoveClick(event, cellValues);
            }}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
    //   {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params: GridValueGetterParams) =>
    //       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    //   },
  ];

  return (
    <div style={{ margin: "1em", height: 400, width: "100%" }}>
      <StatusAlert />
      <BasicModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        setModalOpen={setModalOpen}
        cellValues={cellValues}
      />
      <FormDialog
        state={state}
        handleClose={handleClose}
        handleRemove={handleRemove}
        password={password}
        setPassword={setPassword}
      />
      {rows ? (
        <DataGrid
          style={{ height: 650, width: "100%" }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      ) : (
        <SkeletonSelector type={"table"} />
      )}
    </div>
  );
};

export default DataTable;
