import { TFormState } from "../../../globalprops";
//import DOMPurify from "dompurify";
const Advanced = ({ templatedata }: TFormState) => {
  //custom js
  const script = document.createElement("script");
  script.textContent = templatedata?.fullpage?.js || "";
  document.body.appendChild(script);

  //custom css
  document.head.insertAdjacentHTML(
    "beforeend",
    `<style>${templatedata?.fullpage?.css}</style>`,
  );

  templatedata?.fullpage?.externalCSS?.map((fp) => {
    if (fp?.href !== null)
      document.head.insertAdjacentHTML(
        "beforeend",
        `<link rel="stylesheet" href="${fp?.href}" integrity="${fp?.integrity}" crossorigin="${fp?.crossorigin}">`,
      );
  });

  templatedata?.fullpage?.externalJS?.map((fp) => {
    if (fp?.src !== null)
      document.head.insertAdjacentHTML(
        "beforeend",
        `<script src=${fp?.src} integrity=${fp?.integrity} crossorigin=${fp?.crossorigin}></script>`,
      );
  });

  const html = templatedata?.fullpage?.html || ""; //"";
  // if (templatedata && templatedata.fullpage && templatedata.fullpage.html) {
  //   html = templatedata?.fullpage?.html;
  // }

  //const dompurifyconfig={
  // FORCE_BODY: true,
  //ALLOWED_ATTR: ['style', 'class', 'type', 'href', 'rel','onclick'],
  // // must add these tag manually if use this option.
  // ALLOWED_TAGS: [
  //   'img',
  //   'div',
  //   'button',
  //   'h1',
  //   'div',
  //   'a',
  //   'link',
  //   'figure',
  //   'table',
  //   'caption',
  //   'thead',
  //   'tr',
  //   'th',
  //   'tbody',
  //   'td',
  // ],
  //};

  return (
    <>
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: html, //DOMPurify.sanitize(html, dompurifyconfig),
        }}
      ></div>
      {<div className="advanced-theme"></div>}
    </>
  );
};

export default Advanced;
