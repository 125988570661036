import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IKiosk } from "../../globalprops";
import DynamicFields from "../../components/dynamicFields";

type IAdvancedUIconfigProps = {
  kioskState: IKiosk;
  setKioskState: React.Dispatch<React.SetStateAction<IKiosk>>;
  submitForm: any;
  setModalOpen: React.Dispatch<React.SetStateAction<any>>;
};
const AdvancedUIconfig = ({
  kioskState,
  setKioskState,
  submitForm,
  setModalOpen,
}: IAdvancedUIconfigProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [randomKey, setRandomKey] = useState(Date.now() + 8);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const removeJSField = (index: any) => {
    kioskState?.templatemeta?.templatedata?.fullpage?.externalJS?.splice(
      index,
      1,
    );
    setKioskState({ ...kioskState });
  };

  const removeCSSField = (index: any) => {
    kioskState?.templatemeta?.templatedata?.fullpage?.externalCSS?.splice(
      index,
      1,
    );
    setKioskState({ ...kioskState });
  };

  const AddNewJSField = () => {
    setRandomKey((prev) => prev + 1);
    if (kioskState.templatemeta.templatedata.fullpage?.externalJS) {
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState.templatemeta.templatedata,
            fullpage: {
              ...kioskState.templatemeta.templatedata.fullpage,
              externalJS: [
                ...kioskState.templatemeta.templatedata.fullpage.externalJS,
                { key: randomKey, src: "", integrity: "", crossorigin: "" },
              ],
            },
          },
        },
      });
    }
  };

  const AddNewCSSField = () => {
    setRandomKey((prev) => prev + 1);
    if (kioskState.templatemeta.templatedata.fullpage?.externalCSS) {
      setKioskState({
        ...kioskState,
        templatemeta: {
          templatedata: {
            ...kioskState.templatemeta.templatedata,
            fullpage: {
              ...kioskState.templatemeta.templatedata.fullpage,
              externalCSS: [
                ...kioskState.templatemeta.templatedata.fullpage.externalCSS,
                { key: randomKey, href: "", integrity: "", crossorigin: "" },
              ],
            },
          },
        },
      });
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container style={{ paddingBottom: "2em" }}>
        <Grid item xs={2}>
          <TextField
            label="Kiosk Name"
            color="primary"
            disabled
            value={kioskState?.kioskname}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Height"
            color="primary"
            disabled
            // onChange={(e) =>
            //   setKioskState({
            //     ...kioskState,
            //     kioskheight:e?.target?.value
            //   })
            // }
            value={kioskState?.kioskheight}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Width"
            color="primary"
            disabled
            // onChange={(e) =>
            //   setKioskState({
            //     ...kioskState,
            //     kioskwidth:e?.target?.value
            //   })
            // }
            value={kioskState?.kioskwidth}
          />
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={() => setModalOpen((prev: boolean) => !prev)}
          >
            Preview
            <RemoveRedEyeIcon style={{ paddingLeft: "15px" }} />
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <TextField
            label="Template Name"
            color="primary"
            disabled
            value={kioskState?.templatemeta?.templatedata?.templatename}
          />
        </Grid>
      </Grid>
      {/* <Accordion> */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>HTML</Typography>
          <Typography sx={{ color: "text.secondary" }}>
            HTML (html body content goes here) related properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="outlined-multiline-flexible"
                  label="Html"
                  value={kioskState?.templatemeta?.templatedata?.fullpage?.html}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          fullpage: {
                            ...kioskState?.templatemeta?.templatedata?.fullpage,
                            html: e?.target?.value || "",
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            External Javascript Assets
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            External Javascript (Js array of resources) Links
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <DynamicFields
                type="advanced-template-js"
                removeField={removeJSField}
                kioskState={kioskState}
                setKioskState={setKioskState}
              />
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button variant="outlined" onClick={AddNewJSField}>
                  Add New
                </Button>
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            External CSS Assets
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            External CSS (Custom Style Sheet array of resources) Links
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <DynamicFields
                type="advanced-template-css"
                removeField={removeCSSField}
                kioskState={kioskState}
                setKioskState={setKioskState}
              />
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button variant="outlined" onClick={AddNewCSSField}>
                  Add New
                </Button>
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Custom JS/CSS
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Custom JS/CSS (custom javascript/css content goes here) related
            properties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} variant={"body2"}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="outlined-multiline-flexible"
                  label="Javascript"
                  // multiline={false}
                  // size="medium"
                  // maxRows={50000}
                  value={kioskState?.templatemeta?.templatedata?.fullpage?.js}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          fullpage: {
                            ...kioskState?.templatemeta?.templatedata?.fullpage,
                            js: e?.target?.value,
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="outlined-multiline-flexible"
                  label="CSS"
                  // multiline={false}
                  // size="medium"
                  // maxRows={50000}
                  placeholder="Use CSS syntax. Ex: body{background-color:red;height:400px;width:500px;}"
                  value={kioskState?.templatemeta?.templatedata?.fullpage?.css}
                  onChange={(e) =>
                    setKioskState({
                      ...kioskState,
                      templatemeta: {
                        templatedata: {
                          ...kioskState?.templatemeta?.templatedata,
                          fullpage: {
                            ...kioskState?.templatemeta?.templatedata?.fullpage,
                            css: e?.target?.value,
                          },
                        },
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* </Accordion> */}
      <Grid container justifyContent="center">
        <Grid>
          <Button
            variant="outlined"
            style={{ margin: "2em" }}
            color="primary"
            onClick={submitForm}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdvancedUIconfig;
