import { TButton, TFormState } from "../../../globalprops";
import HorizontalHeader from "./horizontalheader.component";

const HorizontalTemplate = ({ templatedata }: TFormState) => {
  return (
    <div style={templatedata?.cssProps?.theme}>
      <div className="banners" style={{ display: "flex", height: "0em" }}>
        <div className="banner-left">
          {templatedata?.horizontalTemplate?.bannerLeft?.image?.isVisible ? (
            <img
              src={templatedata?.horizontalTemplate?.bannerLeft?.image?.src}
              alt="banner-left-image"
              style={templatedata?.horizontalTemplate?.bannerLeft?.image.css}
            />
          ) : templatedata?.horizontalTemplate?.bannerLeft?.video?.isVisible ? (
            <iframe
              src={templatedata?.horizontalTemplate?.bannerLeft?.video?.src}
              frameBorder="0"
              style={templatedata?.horizontalTemplate?.bannerLeft?.video?.css}
            ></iframe>
          ) : (
            ""
          )}
        </div>

        <div className="banner-right">
          {templatedata?.horizontalTemplate?.bannerRight?.image?.isVisible ? (
            <img
              src={templatedata?.horizontalTemplate?.bannerRight?.image?.src}
              alt="banner-right-image"
              style={templatedata?.horizontalTemplate?.bannerRight?.image.css}
            />
          ) : templatedata?.horizontalTemplate?.bannerRight?.video
              ?.isVisible ? (
            <iframe
              src={templatedata?.horizontalTemplate?.bannerRight?.video?.src}
              frameBorder="0"
              style={templatedata?.horizontalTemplate?.bannerRight?.video?.css}
            ></iframe>
          ) : (
            ""
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="header">
          <HorizontalHeader templatedata={templatedata} />
        </div>
        <div className="buttongrps" style={templatedata?.cssProps?.buttongrp}>
          {templatedata?.buttons
            ?.sort((obj1: any, obj2: any) => obj1?.order - obj2?.order)
            .map((item: TButton, key) => {
              return (
                <a href={item.linkto} key={key} style={{ height: "0px" }}>
                  <img
                    src={item.imagesrc}
                    alt={"button-" + key}
                    style={item?.css}
                  />
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HorizontalTemplate;
