const NoPage = () => {
  return (
    <h1
      style={{
        display: "flex",
        height: "100vh",
        textAlign: "center",
        color: "red",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      The requested page is not found!
    </h1>
  );
};

export default NoPage;
