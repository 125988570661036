import { useState } from "react";
import logo from "../../logo.svg";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import { Signin } from "../../hooks/useAuth";

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fffbfb;
  padding: 2em;
  border-radius: 1em;
  button {
    margin: 2em;
  }
  h1 {
    color: #1681da;
  }
`;
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const signIn = async () => {
    console.log("signing in");
    //Signin(email,password);
    const respond = await Signin(email, password);
    if (respond) {
      StatusAlertService.showError(`Error: ${respond}`);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  return (
    <LoginWrapper>
      <StatusAlert />
      <img src={logo} alt="" height={200} width={200} />
      <h1>Welcome to Pivnet Kiosk manager</h1>
      <FormGroup>
        <FormControl sx={{ m: 3, width: "40ch" }} variant="outlined">
          <TextField
            required
            id="outlined-required"
            label="Email"
            defaultValue=""
            onChange={(e: any) => setEmail(() => e.target.value)}
          />
        </FormControl>

        <FormControl sx={{ m: 3, width: "40ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            onChange={(e: any) => setPassword(() => e.target.value)}
          />
        </FormControl>
        <Button variant="contained" onClick={signIn}>
          Sign In
        </Button>
      </FormGroup>
    </LoginWrapper>
  );
};

export default Login;
