import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { isUserSignedIn } from "../../hooks/useAuth";
import Login from "../login";
import Navbar from "../../components/navbar";
import DataTable from "../../components/datatable";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.h3,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: "center",
}));

const Admin = () => {
  return (
    <>
      {isUserSignedIn() ? (
        <>
          <Navbar />
          <Div>List Of Kioskes</Div>
          <Container maxWidth="lg">
            <DataTable />
          </Container>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Admin;
